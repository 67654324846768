import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUserFromApi } from "services/auth.service";
import { Helmet } from "react-helmet-async";
import CommonLayout from "./CommonLayout";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import IntlTelInput from 'intl-tel-input/react';
import { updateUser } from "services/auth.service";
import { UpdateUserRequestDto } from "types/auth.types";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: React.FC<AccountPageProps> = ({ className }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<UpdateUserRequestDto>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: ""
  });
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
  const intlTelInputRef = useRef<any>(null);

  const fetchCurrentUser = async () => {
    const currentUserResponse = await getCurrentUserFromApi();
    if (currentUserResponse.hasError) {
      setError(currentUserResponse.errorMessage);
      return;
    }
    
    if (currentUserResponse.phoneNumber) {
      setPhoneValue(currentUserResponse.phoneNumber);
      
      if (intlTelInputRef.current) {
        intlTelInputRef.current.getInstance().setNumber(currentUserResponse.phoneNumber);
      }
    }
    setFormData({
      firstName: currentUserResponse.firstName,
      lastName: currentUserResponse.lastName,
      email: currentUserResponse.email,
      phoneNumber: currentUserResponse.phoneNumber || ""
    });
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (!isPhoneValid) {
      setError("Lütfen geçerli bir telefon numarası giriniz.");
      return;
    }

    setIsLoading(true);

    try {
      const submitData = {
        ...formData,
        phoneNumber: phoneValue ? `${phoneValue}` : undefined
      };
      await updateUser(submitData);
      setSuccess("Hesap başarıyla güncellendi");
    } catch (err: any) {
      setError(err.response?.data?.error || "Hesap güncellenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>TODOGO | Hesabım</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          
          {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="text-sm text-red-700">{error}</div>
              </div>
            )}
          {success && (
              <div className="rounded-md bg-green-50 p-4">
                <div className="text-sm text-green-700">{success}</div>
              </div>
            )}
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Hesabım</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <form className="flex-grow  max-w-3xl space-y-6" onSubmit={handleSubmit}>
              <div>
                <Label>Ad</Label>
                <Input className="mt-1.5" defaultValue={formData?.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} disabled={isLoading}/>
              </div>
              <div>
                <Label>Soyad</Label>
                <Input className="mt-1.5" defaultValue={formData?.lastName} onChange={(e) => setFormData({ ...formData, lastName: e.target.value })} disabled={isLoading} />
              </div>
              <div>
                <Label>Email</Label>
                <Input className="mt-1.5" defaultValue={formData?.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} disabled={isLoading} />
              </div>
              <div>
                <Label>Telefon</Label>
                <IntlTelInput
                    ref={intlTelInputRef}
                    initialValue={phoneValue}
                    onChangeNumber={setPhoneValue}
                    onChangeValidity={setIsPhoneValid}
                    onChangeCountry={(e) => {
                      setTimeout(() => {
                        const searchInput = document.querySelector('.iti__search-input') as HTMLInputElement;
                        if (searchInput) {
                          searchInput.placeholder = "Ara";
                        }
                      }, 0);
                    }}
                    onChangeErrorCode={(e) => {
                      if (e !== null) {
                        const input = document.querySelector('.iti__tel-input');
                        input?.setAttribute('placeholder', e == 2 ? "Lütfen bu alanı doldurun." : errorMap[e]);
                        input?.classList.add('placeholder-red-400');
                      }
                    }}
                    initOptions={{
                      initialCountry: "tr",
                      separateDialCode: true,
                      strictMode: true,
                      loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
                      dropdownContainer: document.body
                    }}
                    disabled={isLoading}
                  />
              </div>
              <div className="pt-2">
                <ButtonPrimary type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
                      Hesap Güncelleniyor...
                    </div>
                  ) : (
                    "Güncelle"
                  )}
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
