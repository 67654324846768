import React, { FC, useState, useEffect } from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
import RentalCarSearchForm from "./(car-search-form)/RentalCarSearchForm";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";
import { useTranslation } from 'react-i18next';

export type SearchTab = "flight" | "hotel" | "transfer";


export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "flight" | "hotel" | "transfer";
  formFlightData?: any;
  formStayData?: any;
  onTabChange?: (tab: SearchTab) => void;
  marginTop?: string;

}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "flight",
  currentPage,
  formFlightData,
  formStayData,
  onTabChange,
  marginTop,
}) => {
  const { t } = useTranslation('home');
  const tabs: SearchTab[] = ["flight", "hotel", "transfer"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const handleTabClick = (tab: SearchTab) => {
    setTabActive(tab);
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => handleTabClick(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{t(`heroSearchForm.${tab}`)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "hotel":
        return <StaySearchForm formStayData={formStayData} />;
      case "transfer":
        return <RentalCarSearchForm />;
      case "flight":
        return <FlightSearchForm 
          formFlightData={formFlightData}/>;
      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      style={{ marginTop: marginTop }}
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
