import { MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, FC } from "react";
import axios from "axios";
import { SearchItem } from "types/common.types";
import { useTranslation } from "react-i18next";


interface Props {
  onClick?: () => void;
  onChange?: (value: SearchItem) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  placeHolder?: string;
  desc?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  value?: any;
  hasError?: boolean;
  type: 'hotel' | 'flight' | 'car' | 'experiences';
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "",
  headingText = "Nereye gitmek istiyorsunuz?",
  placeHolder = "Nereye",
  desc = "Nereye gitmek istiyorsunuz?",
  type = "flight",
  value: propsValue = "",
  hasError = false,
}) => {
  const { t } = useTranslation('home');
  const [value, setValue] = useState<string | SearchItem>(defaultValue);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [delay, setDelay] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [valueList, setValueList] = useState<SearchItem[]>([]);

  const handleSelectLocation = (item: SearchItem) => {
    setValue(item.display);
    if (onChange) {
      onChange(item);
    }
  };

  const searchAirport = async (airport:string) => {
    if (airport.length >= 3){
      var response = await axios.get("/api/airport/search/"+airport);
      setValueList(response.data);
    }
  }

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: any;
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item:SearchItem) => (
            <div
              className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
              onClick={() => handleSelectLocation(item)}
              key={item.code}
            >
              <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
              <span>{typeof item === 'string' ? item : item.display}</span>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={className} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-base sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
  <span className="absolute left-2.5 top-1/2 -translate-y-1/2">
    <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
  </span>
  <input
    className={`block w-full bg-transparent border-1 px-10 py-3 pr-12 border-neutral-500 dark:border-neutral-500 rounded-xl focus:ring-0 focus:outline-none focus:border-neutral-500 dark:focus:border-neutral-500 text-sm font-medium leading-none placeholder-black dark:placeholder-neutral-400 truncate placeholder:truncate text-black`}
    value={typeof value === 'string' ? value : value.display}
    onChange={(e) => {
      const newValue = e.currentTarget.value;
      setValue(newValue);

      if (onChange) {
        onChange(newValue as any);
      }
      // Eğer 3 karakterden fazla değilse istek yapılmaz
      if (newValue.length >= 3) {
        // Önceki timeout'u iptal ediyoruz
        if (delay) clearTimeout(delay);

        // 300ms gecikme ile yeni timeout başlatıyoruz
        setDelay(
          setTimeout(async () => {
            if (type === 'flight'){
              await searchAirport(newValue);
            }
          }, 300)
        );
      }else{
        setValueList([]);
      }
    }}
  />
</div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: t('heroSearchForm.popularSearches'),
                items: valueList.length > 0 ? valueList : type === 'flight' ? [
                  {display:"İstanbul, Türkiye (Tüm Havalimanları)", code:"ISTA"},

                  {display:"İstanbul, Türkiye SAW (Sabiha Gökçen Havalimanı)", code:"SAW"},
                  {display:"İstanbul, Türkiye IST (İstanbul Havalimanı)", code:"IST"},
                  {display:"Ankara, Türkiye ESB (Esenboğa Havalimanı)", code:"ESB"},
                  {display:"İzmir, Türkiye ADB (Adnan Menderes Havalimanı)", code:"ADB"}
                ] : [
                  {display:"İstanbul, Türkiye", code:"ISTA"},
                  {display:"Ankara, Türkiye", code:"ESB"},
                  {display:"Antalya, Türkiye", code:"ADB"},
                  {display:"Bursa, Türkiye", code:"SAW"},
                  {display:"Eskişehir, Türkiye", code:"EsSB"}
                ],
              })
            : renderSearchValues({
                heading: t('heroSearchForm.popularSearches'),
                items: valueList.length > 0 ? valueList : type === 'flight' ? [
                  {display:"İstanbul, Türkiye (Tüm Havalimanları)", code:"ISTA"},

                  {display:"İstanbul, Türkiye SAW (Sabiha Gökçen Havalimanı)", code:"SAW"},
                  {display:"İstanbul, Türkiye IST (İstanbul Havalimanı)", code:"IST"},
                  {display:"Ankara, Türkiye ESB (Esenboğa Havalimanı)", code:"ESB"},
                  {display:"İzmir, Türkiye ADB (Adnan Menderes Havalimanı)", code:"ADB"}
                ] : [
                  {display:"İstanbul, Türkiye", code:"ISTA"},
                  {display:"Ankara, Türkiye", code:"ESB"},
                  {display:"Antalya, Türkiye", code:"ADB"},
                  {display:"Bursa, Türkiye", code:"SAW"},
                  {display:"Eskişehir, Türkiye", code:"EsSB"}
                ],
              })}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
