import { FC } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps} from "components/FlightCard/FlightCard";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useTranslation } from "react-i18next";

export interface SectionGridFilterCardProps {
  className?: string;
  flights: FlightCardProps["data"][];
  departureLocation: string;
  arrivalLocation: string;
  flightDirection: string;
  flightDates: {
    departureDate: Date;
    returnDate?: Date;
  };
  flightClass: string;
  flightGuests: {
    totalGuests: number;
  };
  flightPackage: string;
  flightDuration: string;
  onFlightSelect: (flight: FlightCardProps['data']) => void;
  isSelectingReturn: boolean;
  selectedDepartFlight?: FlightCardProps["data"];
  onCancelDepartFlight: () => void;
  stopPoints: { name: string }[];
  onStopPointsChange: (points: { name: string }[]) => void;
  airlines: { name: string }[];
  typeOfAirlines: { name: string }[];
  onAirlinesChange: (airlines: { name: string }[]) => void;
  departureTimes: [number, number];
  onDepartureTimesChange: (times: [number, number]) => void;
  arrivalTimes: [number, number];
  onArrivalTimesChange: (times: [number, number]) => void;
  priceRange: [number, number];
  onPriceRangeChange: (range: [number, number]) => void;
  tripTimes: number;
  onTripTimesChange: (time: number) => void;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  flights = [],
  departureLocation,
  arrivalLocation,
  flightDirection,
  flightDates,
  flightClass,
  flightGuests,
  onFlightSelect,
  isSelectingReturn,
  selectedDepartFlight,
  onCancelDepartFlight,
  stopPoints,
  onStopPointsChange,
  airlines,
  typeOfAirlines,
  onAirlinesChange,
  departureTimes,
  onDepartureTimesChange,
  arrivalTimes,
  onArrivalTimesChange,
  priceRange,
  onPriceRangeChange,
  tripTimes,
  onTripTimesChange
}) => {
  const { t } = useTranslation('listing');
  //useEffect(() => {
  //  console.log("SectionGridFilterCard: Rendered with props", {
  //    flights,
  //    departureLocation,
  //    arrivalLocation,
  //    flightDirection,
  //    isSelectingReturn,
  //    selectedDepartFlight
  //  });
  //},[flights])

  const handleFlightSelect = (flight: FlightCardProps['data']) => {
    onFlightSelect(flight);
  };

  const getLocationWithoutParentheses = (location: string | undefined) => {
    if (!location) return "";
    return location.replace(/\([^)]*\)/g, '').trim();
  };

  const formatDateObject = (date: Date) => {
    return date.toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  const renderDates = () => {
    if (!flightDates) return '';
    const departureDate = flightDates.departureDate;
    const returnDate = flightDates.returnDate;
    if (returnDate) {
      return `${formatDateObject(departureDate)} - ${formatDateObject(returnDate)}`;
    } else {
      return formatDateObject(departureDate);
    }
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className} text-center`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`${getLocationWithoutParentheses(departureLocation)} - ${getLocationWithoutParentheses(arrivalLocation)}`}
        subHeading={
          <span className="block items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center mt-7">
            <span>{renderDates()}</span>
            <span className="mx-2">·</span>
            {flightDirection == "Tek Yön" ? t('oneWay') : t('roundTrip')}
            <span className="mx-2">·</span>
            {flightClass}
            <span className="mx-2">·</span>
            {flightGuests.totalGuests} {t('flightGuests')}
          </span>
        }
      />
      <div className="mb-8 lg:mb-11">
        <TabFilters
          stopPoints={stopPoints}
          onStopPointsChange={onStopPointsChange}
          airlines={airlines}
          typeOfAirlines={typeOfAirlines}
          onAirlinesChange={onAirlinesChange}
          departureTimes={departureTimes}
          onDepartureTimesChange={onDepartureTimesChange}
          arrivalTimes={arrivalTimes}
          onArrivalTimesChange={onArrivalTimesChange}
          priceRange={priceRange}
          onPriceRangeChange={onPriceRangeChange}
          tripTimes={tripTimes}
          onTripTimesChange={onTripTimesChange}
        />
      </div>
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl">
        {selectedDepartFlight && isSelectingReturn && (
          <div className="relative mb-6">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-1/3">
              <div className="border-t border-neutral-200 dark:border-neutral-700"></div>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 pb-6">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-neutral-800 rounded-full px-2 py-2 shadow-md z-9 flex items-center border border-neutral-100 dark:border-neutral-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div className="mb-6">
                <FlightCard
                  data={selectedDepartFlight}
                  onSelect={() => {}}
                  defaultOpen={false}
                  isSelected={true}
                />
              </div>
              <button 
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 bg-white dark:bg-neutral-800 rounded-full p-2 shadow-md z-9 hover:bg-neutral-100 dark:hover:bg-neutral-700 border border-neutral-100 dark:border-neutral-700"
                onClick={(e) => {
                  e.stopPropagation();
                  onCancelDepartFlight();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        )}
        {flights.length === 0 ? (
          <div className="text-center py-10">
            <p className="block items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center mb-10">{t('noFlightsFound')}</p>
            <div className="flex justify-center space-x-4 mt-4">
              <ButtonSecondary onClick={() => window.location.reload()}
              className="rounded-xl"  
              >
                {t('refreshSearch')}
              </ButtonSecondary>
              <ButtonSecondary onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="rounded-xl"    
              >
                {t('newSearch')}
              </ButtonSecondary>
            </div>
          </div>
        ) : (
          flights.map((item, index) => (
            <FlightCard
              key={index}
              data={item}
              onSelect={handleFlightSelect}
              isSelected={false}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
