import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useState, useEffect } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { FlightCardProps } from "components/FlightCard/FlightCard";
import axios from "axios";
import { AirSearchResponse, Flight, FlightSegment, SelectableFares } from "types/flight.types";
import { logFlightSearch } from "utils/analytics";
import { useTranslation } from "react-i18next";

export interface ListingFlightsPageProps {
  className?: string;
}

const getFareTypeName = (code: string): string => {
  switch (code.toLowerCase()) {
    case 'ef':
      return 'EcoFly';
    case 'xf':
      return 'ExtraFly';
    case 'pf':
      return 'PrimeFly';
    default:
      return code;
  }
};

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  const { t } = useTranslation('listing');
  const location = useLocation();
  const navigate = useNavigate();
  const formFlightData = location.state;
  const [totalFlightsCount, setTotalFlightsCount] = useState(0);
  const [isValidSearch, setIsValidSearch] = useState(false);
  const [selectedDepartFlight, setSelectedDepartFlight] = useState<FlightCardProps['data'] | null>(null);
  const [isSelectingReturn, setIsSelectingReturn] = useState(false);
  const [stopPoints, setStopPoints] = useState<{ name: string }[]>([]);
  const [airlines, setAirlines] = useState<{ name: string }[]>([]);
  const [departureTimes, setDepartureTimes] = useState<[number, number]>([0, 24]);
  const [arrivalTimes, setArrivalTimes] = useState<[number, number]>([0, 24]);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 5000]);
  const [tripTimes, setTripTimes] = useState(72);

  const [filteredFlights, setFilteredFlights] = useState([]);
  const [displayFlights, setDisplayFlights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [typeOfAirlines, setTypeOfAirlines] = useState<{ name: string }[]>([]);

  useEffect(() => {
    if (!formFlightData) {
      navigate('/');
    } else {
      setIsValidSearch(true);
      getFlights();
      
      localStorage.setItem("formFlightData", JSON.stringify(formFlightData));
      logFlightSearch(formFlightData);
    }
  }, [formFlightData]);
  
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const addDays = (date:Date, days:number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  const getFlights = async () => {
    try {
     setIsLoading(true);
      var passengers = [
        {
          PaxCode: "ADT",
          PaxCount: formFlightData.flightGuests.adultGuests
        }
      ];
      if (formFlightData.flightGuests.childrenGuests > 0){
        passengers.push({
          PaxCode: "CHD",
          PaxCount: formFlightData.flightGuests.childrenGuests
        });
      }
      if (formFlightData.flightGuests.infantGuests > 0){
        passengers.push({
          PaxCode: "INF",
          PaxCount: formFlightData.flightGuests.infantGuests
        });
      }
      const setFilterProps = (flights:Flight[]) => {
        // Get unique airline names from flight segments
        var uniqueAirlines:any[] = [];
        for(var i = 0; i < flights.length; i++){
          for(var b = 0; b < flights[i].flightSegments.length; b++){
            if(!uniqueAirlines.some(airline => airline.name === flights[i].flightSegments[b].markettingAirlineName)){
              uniqueAirlines.push({name: flights[i].flightSegments[b].markettingAirlineName});
            }
          }
        }
        setTypeOfAirlines(uniqueAirlines);
      }
      const convertMinuteToTimeSpan = (minutes:number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours} saat ${mins} dakika`;
      }
      const convertToCamelCase = (str:string) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
          if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
          return index === 0 ? match.toUpperCase() : match.toLowerCase();
        });
      }
      const formatDateForAPI = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      };
      var response = await axios.post<AirSearchResponse>("/api/flight/search", {
        FlightType: formFlightData.flightDirection === "Tek Yön" ? "OW" : "RT",
        CabinType: formFlightData.flightClass,
        DirectFlightsOnly: false,
        AirSearchPaxItems: passengers,
        DepartureAirport: formFlightData.flightLocations.departureLocationCode,
        ArrivalAirport:  formFlightData.flightLocations.arrivalLocationCode,
        DepartureDate: formatDateForAPI(formFlightData.flightDates.departureDate),
        ReturnDate: formFlightData.flightDates.returnDate ? formatDateForAPI(formFlightData.flightDates.returnDate) : null
      });
      const apiFilteredFlights = !response.data.hasError ? response.data.flights.map((x:Flight) => {
        var flight = {
          id: x.id,
          price: x.selectableFares ? x.selectableFares[0].totalFare : x.pricingInfo.totalFare,
          flightDirection: formFlightData.flightDirection,
          flightDirectionId: x.directionInd,
          flightClass: formFlightData.flightClass,
          flightGuests: formFlightData.flightGuests,
          flightLocations: formFlightData.flightLocations,
          flightDates: formFlightData.flightDates,
          flightInfo:{
            segment: x.flightSegments.map((y:FlightSegment) => ({
              airlineLogo: y.providerLogo ? y.providerLogo : "https://ttyd.org.tr/wp-content/uploads/2022/01/pegasus-airlines-eps-vector-logo.png",
              arrivalTime: y.arrivalTime,
              departureTime: y.departureTime,
              flightDuration: convertMinuteToTimeSpan(y.journeyDurationInMinute),
              arrivalCode:y.arrivalAirportCode,
              departureCode:y.departureAirportCode,
              airlineName: y.markettingAirline,
              productId: y.productId,
              flightCode: y.flightNumber,
              marketingAirlineName: y.markettingAirlineName
            }))
          },
          flightType: x.flightSegments.length > 1 ? t('flightType.transferFlight') : t('flightType.directFlight'),
          flightPackage: x.selectableFares 
            ? x.selectableFares.map((y:SelectableFares) => ({
                code: y.fareCodeId,
                codeName: getFareTypeName(y.fareCode),
                baggageWeight: y.checkedBaggageAllowance.pieces + "x" + y.checkedBaggageAllowance.kilos + t('baggageWeight'),
                cabinBaggageWeight: y.carryOnBaggageAllowance.pieces + `x${y.carryOnBaggageAllowance.kilos} ${t('cabinBaggageWeight')}`,
                price: y.totalFare,
                fareRules: y.fareRules
              }))
            : undefined
        }
        
        return flight;
      }) : [];
      setFilterProps(response.data.flights);
      setFilteredFlights(apiFilteredFlights as any);
      const displayFlights = apiFilteredFlights.filter((x:any) => x.flightDirectionId === 0);
      filterFlights(displayFlights);
    } finally {
     setIsLoading(false);
    }
  }
  
  const filterFlights = (displayFlights:any) => {
    const sortedFlights = displayFlights.sort((a:any, b:any) => a.price - b.price);
    setDisplayFlights(displayFlights);
    setTotalFlightsCount(sortedFlights.length);
  };

  const filterTypeOfAirlines = (selectedAirlines: { name: string }[]) => {

    if (!selectedAirlines.length) {
      // If no airlines are selected, show all flights
      const displayFlights = filteredFlights.filter((x:any) => x.flightDirectionId === (isSelectingReturn ? 1 : 0));
      setDisplayFlights(displayFlights);
      return;
    }
    setAirlines(selectedAirlines);
    const selectedAirlineNames = selectedAirlines.map(airline => airline.name);
    
    const filteredByAirline = filteredFlights.filter((flight: any) => {
      // Check if any segment's airline matches the selected airlines
      return flight.flightInfo.segment.some((segment: any) => {
        // Match either MarkettingAirlineName or the mapped airline name
        return selectedAirlineNames.includes(segment.marketingAirlineName);
      });
    });

    const displayFlights = filteredByAirline.filter((x:any) => x.flightDirectionId === (isSelectingReturn ? 1 : 0));
    setDisplayFlights(displayFlights);
  };

  const filterStopPoints = (selectedStopPoints: { name: string }[]) => {
    setStopPoints(selectedStopPoints);
    if (!selectedStopPoints.length) {
      // If no stop points are selected, show all flights
      const displayFlights = filteredFlights.filter((x:any) => x.flightDirectionId === (isSelectingReturn ? 1 : 0));
      setDisplayFlights(displayFlights);
      return;
    }

    setStopPoints(selectedStopPoints);
    const selectedTypes = selectedStopPoints.map(point => point.name);
    
    const filteredByStopPoints = filteredFlights.filter((flight: any) => {
      return selectedTypes.includes(flight.flightType);
    });

    const displayFlights = filteredByStopPoints.filter((x:any) => x.flightDirectionId === (isSelectingReturn ? 1 : 0));
    setDisplayFlights(displayFlights);
  };
  const filterFlightPrice = (selectedPriceRange: [number, number]) => {
    setPriceRange(selectedPriceRange);
    const displayFlights = filteredFlights.filter((x:any) => x.price >= selectedPriceRange[0] && x.price <= selectedPriceRange[1]);
    setDisplayFlights(displayFlights);
  }
  const convertTimeSpanToMinutes = (timeSpan: string): number => {
    const matches = timeSpan.match(/(\d+)\s*saat\s*(\d+)\s*dakika/);
    if (matches) {
      const hours = parseInt(matches[1]);
      const minutes = parseInt(matches[2]);
      return hours * 60 + minutes;
    }
    return 0;
  };

  const filterTripTimes = (selectedTripTimes: number) => {
    setTripTimes(selectedTripTimes);
    // Convert selectedTripTimes from hours to minutes
    const selectedTripTimesInMinutes = selectedTripTimes * 60;
    
    const filteredByDuration = filteredFlights.filter((flight: any) => {
      // Calculate total duration of all segments
      const totalDuration = flight.flightInfo.segment.reduce((total: number, segment: any) => {
        return total + convertTimeSpanToMinutes(segment.flightDuration);
      }, 0);
      
      return totalDuration <= selectedTripTimesInMinutes;
    });

    const displayFlights = filteredByDuration.filter((x: any) => 
      x.flightDirectionId === (isSelectingReturn ? 1 : 0)
    );
    
    setDisplayFlights(displayFlights);
  };

  const filterDepartureTimes = (selectedDepartureTimes: [number, number]) => {
    setDepartureTimes(selectedDepartureTimes);
    const displayFlights = filteredFlights.filter((x: any) => {
      // Get the first segment's departure time (initial departure)
      const initialDepartureTime = parseInt(x.flightInfo.segment[0].departureTime);
      return initialDepartureTime >= selectedDepartureTimes[0] && 
             initialDepartureTime <= selectedDepartureTimes[1];
    });
    setDisplayFlights(displayFlights);
  }

  const filterArrivalTimes = (selectedArrivalTimes: [number, number]) => {
    setArrivalTimes(selectedArrivalTimes);
    const displayFlights = filteredFlights.filter((x: any) => {
      // Get the last segment's arrival time (final arrival)
      const lastSegment = x.flightInfo.segment[x.flightInfo.segment.length - 1];
      const finalArrivalTime = parseInt(lastSegment.arrivalTime);
      return finalArrivalTime >= selectedArrivalTimes[0] && 
             finalArrivalTime <= selectedArrivalTimes[1];
    });
    setDisplayFlights(displayFlights);
  }

  const handleFlightSelect = (flight: FlightCardProps['data']) => {
    const updatedFlight = {
      ...flight
    };
    if (formFlightData.flightDirection === "Tek Yön") {
      navigate('/flight-checkout', { state: { selectedDepartFlight: updatedFlight } });
    } else {
      if (!selectedDepartFlight) {
        setSelectedDepartFlight(updatedFlight);
        setIsSelectingReturn(true);
        //setDirectionId(1);
        //setIsFetching(false);
        //await filterFlights();

        const displayFlights = filteredFlights.filter((x:any) => x.flightDirectionId === 1);
        filterFlights(displayFlights);
      } else {
        navigate('/flight-checkout', {
          state: { 
            selectedDepartFlight: selectedDepartFlight, 
            selectedReturnFlight: updatedFlight 
          } 
        });
      }
    }
  };

  const handleCancelDepartFlight = async () => {
    setSelectedDepartFlight(null);
    setIsSelectingReturn(false);
    //setDirectionId(0);
    //setIsFetching(true);
    await getFlights();
  };

  if (!isValidSearch) {
    return null;
  }

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>TODOGO | {t('title')}</title>
      </Helmet>

      {isLoading && (
        <div className="fixed inset-0 bg-white/95 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-3xl p-10 shadow-xl max-w-xl w-full mx-4">
            {/* Flight Route */}
            <div className="flex items-center justify-between mb-6">
              <div className="flex flex-col items-center space-y-2">
                <i className="las la-plane-departure text-6xl text-neutral-500 dark:text-neutral-400"></i>
                <span className="text-xl font-medium text-neutral-500 dark:text-neutral-400">
                  {formFlightData?.flightLocations?.departureLocationCode}
                </span>
              </div>

              <div className="w-64 mx-12 relative">
                <div className="absolute top-1/2 left-0 right-0">
                  <div className="h-1 w-full bg-neutral-100 rounded">
                    <div className="h-1 bg-neutral-500 dark:bg-neutral-400 rounded animate-progress-soft"></div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center space-y-2">
                <i className="las la-plane-arrival text-6xl text-neutral-500 dark:text-neutral-400"></i>
                <span className="text-xl font-medium text-neutral-500 dark:text-neutral-400">
                  {formFlightData?.flightLocations?.arrivalLocationCode}
                </span>
              </div>
            </div>

            <div className="text-center space-y-6">
              <h3 className="text-xl font-medium text-neutral-800">
                {t('loading.title')}
              </h3>
              <div className="flex justify-center">
                <div className="w-12 h-12 border-4 border-neutral-200 border-t-neutral-500 rounded-full animate-spin"></div>
              </div>

              <p className="text-md text-neutral-500 dark:text-neutral-400">
                {t('loading.message')}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="flight"
          currentTab="flight"
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">{totalFlightsCount} {t('flightCount')}</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 hidden xl:block"
          formFlightData={formFlightData}
          arrivalLocation={formFlightData?.flightLocations?.arrivalLocation}
        />
        
        
        <SectionGridFilterCard 
          className="pb-24 lg:pb-28" 
          flights={displayFlights}
          departureLocation={isSelectingReturn ? formFlightData?.flightLocations?.arrivalLocation : formFlightData?.flightLocations?.departureLocation}
          arrivalLocation={isSelectingReturn ? formFlightData?.flightLocations?.departureLocation : formFlightData?.flightLocations?.arrivalLocation}
          flightDirection={formFlightData?.flightDirection || ""}
          flightDates={formFlightData?.flightDates || {}}
          flightClass={formFlightData?.flightClass || ""}
          flightGuests={formFlightData?.flightGuests || {}}
          onFlightSelect={handleFlightSelect}
          flightDuration={formFlightData?.flightDuration || ""}
          flightPackage={formFlightData?.flightPackage || ""}
          isSelectingReturn={isSelectingReturn}
          selectedDepartFlight={selectedDepartFlight ?? undefined}
          onCancelDepartFlight={handleCancelDepartFlight}
          stopPoints={stopPoints}
          onStopPointsChange={filterStopPoints}
          airlines={airlines}
          typeOfAirlines={typeOfAirlines}
          onAirlinesChange={filterTypeOfAirlines}
          departureTimes={departureTimes}
          onDepartureTimesChange={filterDepartureTimes}
          arrivalTimes={arrivalTimes}
          onArrivalTimesChange={filterArrivalTimes}
          priceRange={priceRange}
          onPriceRangeChange={filterFlightPrice}
          tripTimes={tripTimes}
          onTripTimesChange={filterTripTimes}
        />
      </div>
    </div>
  );
};

export default ListingFlightsPage;
