import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
// import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
// import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
// import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import FrequentlyAskedQuestions from "containers/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import SectionDowloadApp from "./SectionDowloadApp";
import { useTranslation } from 'react-i18next';

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/",
    name: "New York",
    taxonomy: "category",
    count: 8456,
    textCount: "8.456 kez görüntülendi",
    thumbnail:

      "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/",
    name: "Dubai",
    taxonomy: "category",
    count: 7234,
    textCount: "7.234 kez görüntülendi",
    thumbnail:

      "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/",
    name: "Paris",
    taxonomy: "category",
    count: 9872,
    textCount: "9.872 kez görüntülendi",
    thumbnail:

      "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/",
    name: "Londra",
    taxonomy: "category",
    count: 6543,
    textCount: "6.543 kez görüntülendi",
    thumbnail:

      "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "5",
    href: "/",
    name: "Santorini",
    taxonomy: "category",
    count: 5678,
    textCount: "5.678 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "6",
    href: "/",
    name: "New York",
    taxonomy: "category",
    count: 8456,
    textCount: "8.456 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/",
    name: "İsviçre",
    taxonomy: "category",
    count: 4567,
    textCount: "4.567 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/",
    name: "Norveç", 
    taxonomy: "category",
    count: 3789,
    textCount: "3.789 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/",
    name: "İzlanda",
    taxonomy: "category",
    count: 2345,
    textCount: "2.345 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/",
    name: "Avusturya",
    taxonomy: "category",
    count: 6789,
    textCount: "6.789 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/",
    name: "Finlandiya",
    taxonomy: "category",
    count: 5123,
    textCount: "5.123 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "6",
    href: "/",
    name: "Kanada",
    taxonomy: "category",
    count: 7890,
    textCount: "7.890 kez görüntülendi",
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
];

function PageHome() {
  const { t } = useTranslation('home');

  const localizedDemoCats = DEMO_CATS.map(cat => ({
    ...cat,
    textCount: t('popularDestinations.viewCount', { count: cat.count })
  }));

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading={t('blog.title')}
          subHeading={t('blog.subtitle')}
          categories={localizedDemoCats}
          uniqueClassName="PageHome_s1"

        />


        {/* SECTION */}
        <SectionVideos />        
        
        {/* SECTION */}
          <FrequentlyAskedQuestions />
        {/* SECTION */}
          <SectionDowloadApp />
        {/* SECTION */}
          <SectionSubscribe2 />
      </div>
    </div>
  );
}

export default PageHome;
