import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
}


const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
}) => {
  const { t } = useTranslation('home');
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue?.adults || 0

  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue?.children || 0
  );
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue?.infants || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue?.adults || 0);
  }, [defaultValue?.adults]);
  useEffect(() => {
    setGuestChildrenInputValue(defaultValue?.children || 0);
  }, [defaultValue?.children]);
  useEffect(() => {
    setGuestInfantsInputValue(defaultValue?.infants || 0);
  }, [defaultValue?.infants]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue:GuestsObject = {
      adults: guestAdultsInputValue,
      children: guestChildrenInputValue,
      infants: guestInfantsInputValue,
    };
    if (type === "adults") {
      setGuestAdultsInputValue(value);
      newValue.adults = value;
    }
    if (type === "children") {
      setGuestChildrenInputValue(value);
      newValue.children = value;
    }
    if (type === "infants") {
      setGuestInfantsInputValue(value);
      newValue.infants = value;
    }
    onChange && onChange(newValue);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-base sm:text-2xl">
        {t('heroSearchForm.guest')}
      </span>
      <NcInputNumber

        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, "adults")}
        min={1}
        max={7}
        label={t('heroSearchForm.adult')}
        desc={t('heroSearchForm.adultDesc')}
      />
      <NcInputNumber

        className="w-full mt-6"
        defaultValue={guestChildrenInputValue}
        onChange={(value) => handleChangeData(value, "children")}
        max={7}
        label={t('heroSearchForm.child')}
        desc={t('heroSearchForm.childDesc')}
      />


      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestInfantsInputValue}
        onChange={(value) => handleChangeData(value, "infants")}
        max={7}
        label={t('heroSearchForm.infant')}
        desc={t('heroSearchForm.infantDesc')}
      />

    </div>
  );
};

export default GuestsInput;
