import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: () => void;
  isDisabled?: boolean;
  className?: string;
}

const ButtonClear: FC<Props> = ({
  onClick,
  isDisabled = false,
  className = "",
}) => {
  const { t } = useTranslation('home');
  const buttonContent = (
    <span className="flex items-center justify-center">
      {/* <svg

        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-trash3 mr-2"
        viewBox="0 0 16 16"
      >
        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
      </svg> */}
      {t('heroSearchForm.clear')}
    </span>
  );


  const buttonClasses = `w-1/2 flex justify-center text-sm font-medium p-4 bg-white dark:bg-neutral-800 rounded-2xl shadow-lg ${className} hover:bg-[#fafafa] dark:hover:bg-[#1e2535]`;

  if (isDisabled) {
    return (
      <span
        className={`${buttonClasses} bg-gray-400 cursor-not-allowed`}
        aria-disabled="true"
      >
        {buttonContent}
      </span>
    );
  }

  return (
    <button type="button" className={buttonClasses} onClick={onClick}>
      {buttonContent}
    </button>
  );
};

export default ButtonClear;
