import { useState, useEffect } from 'react';

interface CountdownState {
  minutes: number;
  seconds: number;
}

const useCountdown = (initialMinutes: number, initialSeconds: number, startTimestamp: number | null) => {
  const [state, setState] = useState<CountdownState>(() => {
    if (!startTimestamp) {
      return { minutes: initialMinutes, seconds: initialSeconds };
    }

    const now = Date.now();
    const elapsedSeconds = Math.floor((now - startTimestamp) / 1000);
    const totalInitialSeconds = initialMinutes * 60 + initialSeconds;
    const remainingSeconds = Math.max(0, totalInitialSeconds - elapsedSeconds);
    
    return {
      minutes: Math.floor(remainingSeconds / 60),
      seconds: remainingSeconds % 60
    };
  });

  useEffect(() => {
    if (!startTimestamp) {
      return;
    }

    // startTimestamp değiştiğinde state'i yeniden hesapla
    const now = Date.now();
    const elapsedSeconds = Math.floor((now - startTimestamp) / 1000);
    const totalInitialSeconds = initialMinutes * 60 + initialSeconds;
    const remainingSeconds = Math.max(0, totalInitialSeconds - elapsedSeconds);
    
    setState({
      minutes: Math.floor(remainingSeconds / 60),
      seconds: remainingSeconds % 60
    });

    if (remainingSeconds <= 0) {
      return;
    }

    const countdown = setInterval(() => {
      setState(currentState => {
        const totalSeconds = currentState.minutes * 60 + currentState.seconds;
        if (totalSeconds <= 0) {
          clearInterval(countdown);
          return currentState;
        }

        const newTotalSeconds = totalSeconds - 1;
        return {
          minutes: Math.floor(newTotalSeconds / 60),
          seconds: newTotalSeconds % 60
        };
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [startTimestamp, initialMinutes, initialSeconds]);

  return state;
};

export default useCountdown;