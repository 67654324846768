/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useCallback, useMemo, memo, useRef } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import NcModal from "shared/NcModal/NcModal";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { FlightCardProps } from "components/FlightCard/FlightCard";
import Heading2 from "components/Heading/Heading2";
import Select from "shared/Select/Select";
import paymentCardsImage from "images/payment-cards.png";
import useCountdown from "./useCountdown";
import IntlTelInput, { intlTelInput } from 'intl-tel-input/react';
import 'intl-tel-input/build/css/intlTelInput.css';
import { Helmet } from "react-helmet-async";

import Checkbox from "shared/Checkbox/Checkbox";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AirBooking, Flight, PaymentResponse, FlightSegment, InstallmentOption, ShoppingCartResponse, FlightAllocateResponse, Passenger, FlightReservationResponse, AllocateItem, FlightAllocateRequest } from "types/flight.types";
import { getSavedPassengers } from "services/passenger.service";
import { SavedPassenger } from "types/auth.types";
import { getCurrentUser } from "services/auth.service";
import { logCheckoutEvent } from "utils/analytics";
import axios from "utils/axios";
import CheckoutAccordion from "components/Accordion/CheckoutAccordion";


export interface CheckOutPagePageMainProps {
  className?: string;
  selectedPackage?: string;
}


interface FlightData {
  shoppingCartId: string;
  passengers: Array<{
    paxReferenceId:string;
    paxType:string;
  }>
  selectedDepartFlight: FlightCardProps["data"] | null;
  selectedReturnFlight: FlightCardProps["data"] | null; 
  isAllocated: boolean;
  isPrebooked: boolean;
  installmentOptions: Array<InstallmentOption>;
  allocateTimestamp: number | null;
  totalAmount: number;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
  selectedPackage,
}) => {
  const [invoiceType, setInvoiceType] = useState("Bireysel");
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({});
  const [paymentClassName, setPaymentClassName] = useState("disabled");
  const [paymentInputsDisabled, setPaymentInputsDisabled] = useState(true);
  const [buttonMessage, setButtonMessage] = useState<React.ReactNode>("Ödemeye İlerle");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [countryCode, setCountryCode] = useState<string>("+90");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<string>("0");
  const [totalDecimal, setTotalDecimal] = useState<string>("0");
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [isTimeoutOccurred, setIsTimeoutOccurred] = useState(false);
  const [installmentOptions, setInstallmentOptions] = useState<InstallmentOption[]>([]);
  const intlTelInputRef = useRef<any>(null);
  const [pendingPhoneNumber, setPendingPhoneNumber] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalSteps, setTotalSteps] = useState(0);
  //const beforeUnloadListenerRef = useRef<(e: BeforeUnloadEvent) => void>();

  const formatPrice = (price: number): [string, string] => {
    const [integerPart, decimalPart] = price.toFixed(2).split(".");
    return [integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "."), decimalPart];
  };

  const calculateTotalPrice = () => {
    let total = flightData?.totalAmount || 0;
    const [integerPart, decimalPart] = formatPrice(total);
    setTotalPrice(integerPart);
    setTotalDecimal(decimalPart);
    return total;
  };

  const location = useLocation();
  
  const formatDateObject = (date: Date | null) => {
    if (!date) return "";
    return date.toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' });
  };
  
  const [flightData, setFlightData] = useState<FlightData | null>(null);
  const [savedPassengers, setSavedPassengers] = useState<SavedPassenger[]>([]);
  const [selectedPassengers, setSelectedPassengers] = useState<{ [key: number]: string }>({});

  // State tanımlamalarının olduğu bölüme eklenecek
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [isPaymentStep, setIsPaymentStep] = useState(false);

  // Adım geçişlerini yönetecek fonksiyonlar
  const handleStepComplete = (step: number) => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    setActiveStep(step + 1);
  };

  const handleStepToggle = (step: number) => {
    if (isPaymentStep && step !== totalSteps) {
      toast.warning("Rezervasyon tamamlandı. Sadece ödeme adımına devam edebilirsiniz.", {
        position: "top-right",
        autoClose: 3000
      });
      return;
    }
    if (!isStepDisabled(step)) {
      setActiveStep(step);
    }
  };

  const isStepDisabled = (step: number) => {
    if (step === 1) return false;
    return !completedSteps.includes(step - 1);
  };

  const isStepCompleted = (step: number) => {
    return completedSteps.includes(step);
  };

  const { minutes, seconds } = useCountdown(15, 0, flightData?.allocateTimestamp || null);

  const [generalError, setGeneralError] = useState<string | null>(null);
  const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

  // Sayfa yenileme ve sayfadan ayrılma durumlarında uyarı göster
  //beforeUnloadListenerRef.current = (e: BeforeUnloadEvent) => {
  //  const message = "Sayfadan ayrılmak istediğinize emin misiniz? Rezervasyon bilgileriniz kaybolabilir.";
  //  e.preventDefault();
  //  e.returnValue = message;
  //  return message;
  //};

  useEffect(() => {
    if (location.state) {
      setFlightData(location.state as FlightData);
    } else {
      const params = new URLSearchParams(location.search);
      const cartId = params.get('ShoppingCartId');
      if (cartId) {
        fetchShoppingCartData(cartId);
      } else {
        navigate('/listing-flights');
      }
    }
    logCheckoutEvent('flight');

    //window.addEventListener('beforeunload', beforeUnloadListenerRef.current!);

    //return () => {
    //  window.removeEventListener('beforeunload', beforeUnloadListenerRef.current!);
    //};
  }, [location]);

  const fetchShoppingCartData = async (cartId: string) => {
    try {
      const response = await axios.get<ShoppingCartResponse>(`/api/flight/payment-cart/${cartId}`);
      if (!response.data.hasError) {
        const cartData = response.data.shoppingCart;
        var flights = cartData.airBookings.map((flight: AirBooking) => ({
          id: flight.id,
          selectedPackage: flight.fareCode,
          flightGuests: {
            adultGuests: cartData.passengers.filter((p: any) => p.paxType === 'ADT').length,
            childrenGuests: cartData.passengers.filter((p: any) => p.paxType === 'CHD').length,
            infantGuests: cartData.passengers.filter((p: any) => p.paxType === 'INF').length,
            totalGuests: cartData.passengers.length,
          },
          flightLocations: {
            departureLocation: flight.flightSegments[0].departureAirportName,
            departureLocationCode: flight.flightSegments[0].departureAirportCode,
            arrivalLocation: flight.flightSegments[0].arrivalAirportName,
            arrivalLocationCode: flight.flightSegments[0].arrivalAirportCode,
          },
          flightDates: {
            departureDate: new Date(flight.flightSegments[0].departureDateTime),
            returnDate: flight.flightSegments.length > 1 ? new Date(flight.flightSegments[1].departureDateTime) : null,
          },
          flightInfo: {
            segment: flight.flightSegments.map((segment: FlightSegment) => ({
              airlineName: segment.markettingAirline,
              departureCode: segment.departureAirportCode,
              arrivalCode: segment.arrivalAirportCode,
              flightCode: segment.flightNumber,
              departureTime: segment.departureDateTime.split('T')[1].slice(0, 5),
              arrivalTime: segment.arrivalDateTime.split('T')[1].slice(0, 5),
              flightDuration: `${Math.floor(segment.journeyDurationInMinute / 60)} saat ${segment.journeyDurationInMinute % 60} dakika`,
            })),
          },
          price: flight.totalFare,
          flightType: flight.flightSegments.length > 1 ? "Aktarmalı Uçuş" : "Direkt Uçuş",
          flightDirection: flight.flightSegments.length > 1 ? "Gidiş - Dönüş" : "Tek Yön",
        }));
        setFlightData({
          shoppingCartId: cartId,
          passengers: cartData.passengers.map((p: any) => ({ paxReferenceId: p.paxReferenceId, paxType: p.paxType })),
          selectedDepartFlight: flights[0] as FlightCardProps["data"],
          selectedReturnFlight: flights.length > 1 ? flights[1] as FlightCardProps["data"] : null,
          isAllocated: true,
          isPrebooked: true,
          installmentOptions: cartData.paymentOption.installmentOptions,
          allocateTimestamp: new Date(cartData.createdDateTime).getTime(),
          totalAmount: cartData.remainingTotal
        });

        // Form verilerini doldur
        const initialFormData: { [key: string]: string } = {};
        cartData.passengers.forEach((passenger: any, index: number) => {
          initialFormData[`passengerName${index}`] = passenger.firstName;
          initialFormData[`passengerSurname${index}`] = passenger.lastName;
          initialFormData[`passengerBirthDate${index}`] = formatDate(passenger.birthDate);
          initialFormData[`passengerGender${index}`] = passenger.gender.toLowerCase();
          initialFormData[`passengerNationality${index}`] = passenger.nationality;
          if (passenger.Nationality === 'TR' ) {
            initialFormData[`passengerIdentityNumber${index}`] = passenger.citizenNo;
          } else {
            initialFormData[`passengerPassportNumber${index}`] = passenger.passportNo;
            initialFormData[`passengerPassportExpiry${index}`] = formatDate(passenger.passportExpireDate);
          }
        });

        // İletişim bilgilerini doldur
        initialFormData.contactName = cartData.contact.name;
        initialFormData.contactSurname = cartData.contact.lastName;
        initialFormData.contactPhone = cartData.contact.phone;
        initialFormData.contactEmail = cartData.contact.email;
        
        // set IntlTelInput input value
        const phone = cartData.contact.phone;
        setPhoneValue(phone);
        if (intlTelInputRef.current) {
          intlTelInputRef.current.getInstance().setNumber(phone);
        }

        setFormData(initialFormData);
        calculateTotalPrice();
        setIsPaymentStep(true);
        
        const totalSteps = cartData.passengers.length + 3;
        setTotalSteps(totalSteps);
        // Tüm adımları tamamlanmış olarak işaretle
        const allSteps = Array.from({ length: totalSteps - 1 }, (_, i) => i + 1);
        setCompletedSteps(allSteps);
        // Ödeme adımına geç
        setActiveStep(totalSteps);
      }
    } catch (error) {
      console.error('Shopping cart data fetch error:', error);
      navigate('/listing-flights');
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (flightData){
      if (!flightData!.isAllocated){
        allocateFLights();
      }else{
        calculateTotalPrice();
      }
      console.log(flightData);
    }
  }, [flightData]);

  const allocateFLights = async () => {
    var allocateItems: AllocateItem[] = [
      {
        productId: flightData!.selectedDepartFlight!.id,
        fareCodeId: flightData!.selectedDepartFlight!.selectedPackage
      }
    ];

    if (flightData!.selectedReturnFlight) {
      allocateItems.push({
        productId: flightData!.selectedReturnFlight.id,
        fareCodeId: flightData!.selectedReturnFlight.selectedPackage
      });
    }

    var request: FlightAllocateRequest = {
      allocateItems: allocateItems
    };
    
    try {
      var response = await axios.post<FlightAllocateResponse>("/api/flight/allocate", request);

      if (!response.data.hasError && response.data.shoppingCartId) {
        const timestamp = Date.now();
        flightData!.isAllocated = true;
        flightData!.shoppingCartId = response.data.shoppingCartId;
        flightData!.passengers = response.data.passengers.map((p:Passenger) => ({ paxReferenceId: p.paxReferenceId, paxType: p.paxType }));
        flightData!.allocateTimestamp = timestamp;
        flightData!.totalAmount = response.data.totalAmount;
        
        const initialFormData: { [key: string]: string } = {};
        const passengerCount = flightData!.passengers!.length;
        const calculatedTotalSteps = passengerCount + 3; // Yolcu adımları + İletişim + Fatura + Ödeme
        
        // totalSteps'in minimum 4 olduğundan emin oluyoruz (en az 1 yolcu + 3 sabit adım)
        const safeTotal = Math.max(4, calculatedTotalSteps);
        setTotalSteps(safeTotal);
        
        for (let i = 0; i < passengerCount; i++) {
          initialFormData[`passengerNationality${i}`] = "TR";
        }
        
        setFormData(initialFormData);
        setFlightData(flightData);
        calculateTotalPrice();
      } else {
        // show error message to user
        console.error("Flight allocation error:", response.data.errorMessage);
        navigate('/listing-flights');
      }
    } catch (error) {
      console.error("Flight allocation error:", error);
      navigate('/listing-flights');
    }
  };
  
  const getLocationWithoutParentheses = (location: string | undefined) => {
    if (!location) return "";
    return location.replace(/\([^)]*\)/g, "").trim();
  };

  const navigate = useNavigate();

  const [cardInfo, setCardInfo] = useState({
    cardHolder: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardType: "",
    installmentId: "",
  });

  const [cardErrors, setCardErrors] = useState({
    cardHolder: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const getPassengerTypeName = (paxType:string) => {
    switch (paxType){
      case "ADT": return "Yolcu";
      case "CHD": return "Çocuk";
      case "INF": return "Bebek";
    }
  }
  
  function organizeFormData(formData: { [key: string]: string }) {
    console.log("Form verisi düzenleniyor");
    const organizedData = {
      passengers: [] as any[],
      contactInfo: {} as any,
      invoiceInfo: {} as any,
      paymentResult: {} as any,
    };

    const passengerCount =
      Math.max(
        ...Object.keys(formData)
          .filter((key) => key.startsWith("passengerName"))
          .map((key) => parseInt(key.replace("passengerName", "")))
      ) + 1;

    for (let i = 0; i < passengerCount; i++) {
      const identityNumber = formData[`passengerIdentityNumber${i}`];
      console.log(`Passenger ${i} identity number:`, identityNumber);
      organizedData.passengers.push({
        name: formData[`passengerName${i}`] || "",
        surname: formData[`passengerSurname${i}`] || "",
        birthDate: formData[`passengerBirthDate${i}`] || "",
        identityNumber: formData[`passengerIdentityNumber${i}`] || "",
        nationality: formData[`passengerNationality${i}`] || "TR",
        passportNumber: formData[`passengerPassportNumber${i}`] || "",
        passportExpiry: formData[`passengerPassportExpiry${i}`] || "",
        gender: formData[`passengerGender${i}`] || "",
        paxType: flightData!.passengers[i].paxType,
        paxReferenceId: flightData!.passengers[i].paxReferenceId,
      });
    }

    
    organizedData.contactInfo = {
      name: formData.contactName || "",
      surname: formData.contactSurname || "",
      phone: formData.countryCode ? `${formData.countryCode}${formData.contactPhone}` : formData.contactPhone,
      email: formData.contactEmail || "",
    };


    organizedData.invoiceInfo = {
      type: formData.invoiceType || "Bireysel",
      city: formData.invoiceCity || "",
      district: formData.invoiceDistrict || "",
      address: formData.invoiceAddress || "",
      billingName: formData.billingName || "",
      taxOffice: formData.taxOffice || "",
      taxNumber: formData.taxNumber || "",
      country: "TR"
    };

    return organizedData;
  }

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleTermsChange = (checked: boolean) => {
    setIsTermsAccepted(checked);
  };

  const isOnlyLetters = (str: string) => /^[A-Za-zğüşıöçĞÜŞİÖÇ\s]+$/.test(str);

  const IsTroyCard = (binCode:string) => 
  {
      // Troy kartları için bilinen BIN kodları
      const troyBins = [ "365770", "365", "9792", "900", "654", "90000", "65" ];
      for (const bin in troyBins)
      {
        if (binCode.startsWith(bin))
        {
            return true;
        }
      }  
      return false;
  }

  const handleCardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedValue = value;
    let error = "";

    switch (name) {
      case "cardHolder":
        formattedValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, "");
        error = formattedValue
          ? isOnlyLetters(formattedValue)
            ? ""
            : ""
          : "Lütfen geçerli bir kart sahibi adı ve soyadı girin.";
        break;
      case "cardNumber":
        formattedValue = value
          .replace(/\D/g, "")
          .replace(/(\d{4})/g, "$1 ")
          .trim();
        formattedValue = formattedValue.substring(0, 19);
        const cardNumber = formattedValue.replace(/\s/g, "");
        if (cardNumber.length >= 6){
          // TODO: get installment options by bin number
          const binNumber = cardNumber.substring(0, 6);

          if (cardNumber.startsWith("4"))
          {
              cardInfo.cardType = "Visa";
          }
          
          // MasterCard kontrolü (51-55 ve 2221-2720 arası numaralar)
          if ((parseInt(cardNumber.substring(0, 2)) >= 51 && parseInt(cardNumber.substring(0, 2)) <= 55) ||
              (parseInt(cardNumber.substring(0, 4)) >= 2221 && parseInt(cardNumber.substring(0, 4)) <= 2720))
          {
            cardInfo.cardType = "MasterCard";
          }
          
          // Troy kartları için genişletilmiş kontrol
          if (IsTroyCard(binNumber))
          {
              cardInfo.cardType =  "Troy";
          }
          // Import and search arkman-bin.json
          const bankBins = require('./arkman-bin.json');
          const foundBank = bankBins.find((bank: any) => bank.code === binNumber);

          if (!foundBank) {
            // If bank not found, return only single installment
            const installments = flightData!.installmentOptions?.filter(
              (option: InstallmentOption) => option.installmentCount === 1
            ) || [];
            setInstallmentOptions(installments);
            cardInfo.installmentId = installments?.length > 0 ? installments[0].installmentOptionId : "";
          }else{
            // Get all installment options for this bank + single installment
            const bankInstallments = flightData!.installmentOptions?.filter(
              (option: InstallmentOption) => 
                option.bankName === foundBank.Bank || 
                option.installmentCount === 1
            );
            // If no bank-specific options found, return single installment
            setInstallmentOptions(bankInstallments?.length > 0 
            ? bankInstallments
            : []);
          }
        }
        error =
        cardNumber.length === 16
            ? ""
            : "Lütfen geçerli bir kart numarası girin.";
        break;
      case "expiryDate":
        if (
          /^(0[1-9]|1[0-2])\/\d{2}$/.test(cardInfo.expiryDate) &&
          value.length > cardInfo.expiryDate.length
        ) {
          return;
        }
        formattedValue = value.replace(/\D/g, "");
        if (formattedValue.length > 2) {
          formattedValue =
            formattedValue.substring(0, 2) +
            "/" +
            formattedValue.substring(2, 4);
        }
        error = /^(0[1-9]|1[0-2])\/\d{2}$/.test(formattedValue)
          ? ""
          : "Lütfen geçerli bir son kullanma tarihi girin.";
        break;
      case "cvv":
        if (
          (cardInfo.cvv.length === 3 || cardInfo.cvv.length === 4) &&
          value.length > cardInfo.cvv.length
        ) {
          return;
        }
        formattedValue = value.replace(/\D/g, "").substring(0, 4);
        error =
          formattedValue.length >= 3 && formattedValue.length <= 4
            ? ""
            : "Lütfen geçerli bir CVV girin.";
        break;
    }

    setCardInfo((prev) => ({ ...prev, [name]: formattedValue }));
    setCardErrors((prev) => ({ ...prev, [name]: error }));
  };

  const isValidTCKN = (value: string) => /^[0-9]{11}$/.test(value);

  const isValidEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    let newValue = value;

    if ((name.includes('Name') && !name.includes('billingName')) || name.includes('Surname') || name === 'invoiceCity' || name === 'invoiceDistrict' || name === 'taxOffice') {
      newValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, '');
    } else if (name === 'billingName') {
      newValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, '');
    } else if (name.includes('BirthDate') || name.includes('PassportExpiry')) {
      newValue = value.replace(/[^\d/]/g, '');
      if (newValue.length === 2 || newValue.length === 5) {
        if (newValue.charAt(newValue.length - 1) !== '/') {
          newValue += '/';
        }
      }
      newValue = newValue.slice(0, 10);
    } else if (name.includes('IdentityNumber')) {
      newValue = value.replace(/\D/g, '').slice(0, 11);
    } else if (name.includes('PassportNumber')) {
      newValue = value.slice(0, 9);
    } else if (name === 'contactPhone' || name === 'taxNumber') {
      newValue = value.replace(/\D/g, '');
      if (name === 'taxNumber') {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === 'contactEmail') {
      newValue = value.toLowerCase();
    } else if (name === 'billingName') {
      newValue = value;
    }

    setFormData(prev => {
      const updatedForm = { ...prev, [name]: newValue };
      
      if (name.includes('Nationality')) {
        console.log(name + " -> " + newValue);
        const index = name.replace('passengerNationality', '');
        if (newValue !== 'TR') {
          delete updatedForm[`passengerIdentityNumber${index}`];
          updatedForm[`passengerPassportNumber${index}`] = updatedForm[`passengerPassportNumber${index}`] || '';
          updatedForm[`passengerPassportExpiry${index}`] = updatedForm[`passengerPassportExpiry${index}`] || '';
        } else {
          updatedForm[`passengerIdentityNumber${index}`] = updatedForm[`passengerIdentityNumber${index}`] || '';
        }
      }
      
      return updatedForm;
    });
    
    if (newValue) {
      setFormErrors(prev => ({ ...prev, [name]: false }));
    }
  };

  const [placeholder, setPlaceholder] = useState<string>("5XX XXX XX XX");
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      contactPhone: phoneValue,
    }));

  }, [phoneValue]);

  const validateForm = (formName: string) => {
    console.log("Form doğrulama başlatıldı");
    const newErrors: { [key: string]: boolean } = {};
    const newCardErrors = { ...cardErrors };
    let isValid = true;
    let firstErrorField: string | null = null;

    switch (formName) {
      case "passenger":
        const passengerCount = flightData?.passengers.length || 0;

        // Yolcu bilgileri kontrolü
        for (let i = 0; i < passengerCount; i++) {
          ["Name", "Surname", "BirthDate", "Gender", "Nationality"].forEach(
            (field) => {
              const key = `passenger${field}${i}`;
              if (!formData[key] || formData[key].trim() === "") {
                newErrors[key] = true;
                isValid = false;
              }
            }
          );

          if (formData[`passengerNationality${i}`] === "TR") {
            if (
              !formData[`passengerIdentityNumber${i}`] ||
              formData[`passengerIdentityNumber${i}`].length !== 11
            ) {
              newErrors[`passengerIdentityNumber${i}`] = true;
              isValid = false;
            }
          } else {
            if (
              !formData[`passengerPassportNumber${i}`] ||
              formData[`passengerPassportNumber${i}`].length !== 9
            ) {
              newErrors[`passengerPassportNumber${i}`] = true;
              isValid = false;
            }
            if (
              !formData[`passengerPassportExpiry${i}`] ||
              formData[`passengerPassportExpiry${i}`].length !== 10
            ) {
              newErrors[`passengerPassportExpiry${i}`] = true;
              isValid = false;
            }
            delete formData[`passengerIdentityNumber${i}`];
          }
        }

        // İletişim bilgileri kontrolü
        ["contactName", "contactSurname", "contactPhone", "contactEmail"].forEach(
          (key) => {
            if (!formData[key]) {
              newErrors[key] = true;
              isValid = false;
            }
          }
        );

        if (!isValidEmail(formData.contactEmail)) {
          newErrors.contactEmail = true;
          isValid = false;
        }

        if (!isPhoneValid) {
          newErrors.contactPhone = true;
          isValid = false;
        }
        break;

      case "invoice":
        // Fatura bilgileri kontrolü
        ["invoiceCity", "invoiceDistrict", "invoiceAddress", "billingName"].forEach((key) => {
          if (!formData[key]) {
            newErrors[key] = true;
            isValid = false;
          }
        });

        if (formData.invoiceType === "Kurumsal") {
          if (!formData.taxNumber || formData.taxNumber.length !== 10) {
            newErrors.taxNumber = true;
            isValid = false;
          }
          if (!formData.taxOffice) {
            newErrors.taxOffice = true;
            isValid = false;
          }
        }

        ["invoiceCity", "invoiceDistrict", "taxOffice"].forEach((field) => {
          if (formData[field] && !isOnlyLetters(formData[field])) {
            newErrors[field] = true;
            isValid = false;
          }
        });
        break;

      case "payment":
        if (cardInfo.cardNumber.replace(/\s/g, "").length !== 16) {
          newErrors.cardNumber = true;
          newCardErrors.cardNumber = "Lütfen geçerli bir kart numarası girin.";
          isValid = false;
        }

        if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardInfo.expiryDate)) {
          newErrors.expiryDate = true;
          newCardErrors.expiryDate = "Lütfen geçerli bir son kullanma tarihi girin.";
          isValid = false;
        }

        if (cardInfo.cvv.length < 3 || cardInfo.cvv.length > 4) {
          newErrors.cvv = true;
          newCardErrors.cvv = "Lütfen geçerli bir CVV girin.";
          isValid = false;
        }

        if (!isTermsAccepted) {
          newErrors.terms = true;
          isValid = false;
        }
        break;
    }

    if (!isValid) {
      let errorMessage = "Lütfen aşağıdaki alanları kontrol ediniz:\n";
      
      Object.keys(newErrors).forEach(key => {
        let singleError = "";
        if (key.startsWith('passenger')) {
          const passengerIndex = key.match(/\d+/)?.[0];
          const fieldName = key.replace(/passenger|[0-9]/g, '');
          
          const fieldLabels: { [key: string]: string } = {
            'Name': 'Ad',
            'Surname': 'Soyad',
            'BirthDate': 'Doğum Tarihi',
            'Gender': 'Cinsiyet',
            'Nationality': 'Uyruk',
            'IdentityNumber': 'T.C. Kimlik No',
            'PassportNumber': 'Pasaport No',
            'PassportExpiry': 'Pasaport S.K.T.'
          };
          singleError = `• ${Number(passengerIndex) + 1}. Yolcu - ${fieldLabels[fieldName]}\n`;
          errorMessage += singleError;
        } else if (key.startsWith('contact')) {
          const fieldLabels: { [key: string]: string } = {
            'contactName': 'İletişim - Ad',
            'contactSurname': 'İletişim - Soyad',
            'contactPhone': 'İletişim - Telefon',
            'contactEmail': 'İletişim - E-posta'
          };
          singleError = `• ${fieldLabels[key]}\n`;
          errorMessage += singleError;
        } else if (key.startsWith('invoice') || key === 'billingName' || key === 'taxNumber' || key === 'taxOffice') {
          const fieldLabels: { [key: string]: string } = {
            'invoiceCity': 'Fatura - İl',
            'invoiceDistrict': 'Fatura - İlçe',
            'invoiceAddress': 'Fatura - Adres',
            'billingName': 'Fatura - Ad Soyad/Firma Adı',
            'taxNumber': 'Fatura - Vergi No',
            'taxOffice': 'Fatura - Vergi Dairesi'
          };
          singleError = `• ${fieldLabels[key]}\n`;
          errorMessage += singleError;
        }
      });

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClick: () => {
          if (firstErrorField) {
            const element = document.querySelector(`[name="${firstErrorField}"]`) as HTMLElement;
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              setTimeout(() => {
                element.focus();
              }, 500);
            }
          }
        },
        style: {
          whiteSpace: 'pre-line',
          cursor: 'pointer'
        }
      });
    }

    setFormErrors(newErrors);
    setCardErrors(newCardErrors);
    return isValid;
  };


  const [showErrors, setShowErrors] = useState(false);

  const parseBirthDate = (str: string) => {
    const [day, month, year] = str.split("/").map(Number);
    // Tarihi UTC olarak oluştur ve ISO string'e çevir
    return new Date(Date.UTC(year, month - 1, day)).toISOString();
  }

  //useEffect(() => {
  //  if (isPaymentStep && beforeUnloadListenerRef.current) {
  //    window.removeEventListener('beforeunload', beforeUnloadListenerRef.current);
  //  }
  //}, [isPaymentStep]);

  const handlePayment = async () => {
    try {
      const organizedData = organizeFormData(formData);
      setIsLoading(true);
      var paymentResponse = await axios.post<PaymentResponse>("/api/flight/payment", {
        ShoppingCartId: flightData!.shoppingCartId,
        Amount: flightData!.totalAmount,
        CardHolderName: cardInfo.cardHolder,
        CardNumber: cardInfo.cardNumber,
        ExpiryDate: cardInfo.expiryDate,
        CVV: cardInfo.cvv,
        CardType: cardInfo.cardType,
        InstallmentId: cardInfo.installmentId,
        InvoiceInfo: {
          Type: organizedData.invoiceInfo.type,
          City: organizedData.invoiceInfo.city,
          District: organizedData.invoiceInfo.district,
          Address: organizedData.invoiceInfo.address,
          BillingName: organizedData.invoiceInfo.billingName,
          TaxOffice: organizedData.invoiceInfo.taxOffice,
          TaxNo: organizedData.invoiceInfo.taxNumber === "" ? (organizedData.passengers[0].nationality === "TR" ? organizedData.passengers[0].identityNumber : "00000000000") : organizedData.invoiceInfo.taxNumber,
          Country: organizedData.invoiceInfo.country
        }
      });
      setIsLoading(false);
      if (!paymentResponse.data.hasError) {
        window.location.href = paymentResponse.data.continueUrl;
      } else {
        setGeneralError(paymentResponse.data.errorMessage);
        toast.error(paymentResponse.data.errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } catch (error) {
      console.error("Ödeme işlemi sırasında hata:", error);
      setGeneralError("Ödeme işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
      setIsLoading(false);
      toast.error("Ödeme işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const renderTimeoutModal = () => (
    <NcModal
      renderTrigger={() => null}
      isOpenProp={showTimeoutModal}
      onCloseModal={() => setShowTimeoutModal(false)}
      modalTitle="Rezervasyon Bilgileri"
      contentExtraClass="max-w-screen-lg"
      renderContent={() => (
        <div className="text-md text-neutral-500 space-y-4">
          <span className="text-justify">
            TODOGO bu koltuğu sizin için ayırmıştı ancak size ayırılmış süre
            içerisinde ödeme yapılmadığı için rezervasyonunuz iptal edildi. {" "} Üzgünüz, ancak{" "}
            <span
              className="cursor-pointer text-secondary-6000"
              onClick={handleSearchAgainMain}
            >
              burayı
            </span>
            {" "}
            kullanarak{" "}
            yeniden
            {" "}
            <span
              className="cursor-pointer text-secondary-6000"
              onClick={handleSearchAgainMain}
            >
              arama {" "}
            </span>
            yapabilirsiniz. Merak etmeyin, sizin için burada olacağız.
          </span>
        </div>
      )}
    />
  );

  const renderInput = (name: string, label: string, placeholder: string) => (
    <div className="flex-1 space-y-1">
      <Label>{label}</Label>
      <Input
        type="text"
        name={name}
        placeholder={formErrors[name] ? "Lütfen bu alanı doldurun." : placeholder}
        value={formData[name] || ""}
        onChange={handleInputChange}
        className={formErrors[name] ? "placeholder-red-400" : ""}
        disabled={!paymentInputsDisabled}
      />
    </div>
  );

  const handleSearchAgainMain = () => {
    setShowTimeoutModal(false);
    navigate("/listing-flights");
  };

  const renderContinueButton = () => {
    return (
      <div className="mt-6">
        <button
          onClick={() => {
            if (activeStep <= flightData!.passengers.length){
              if (validatePassengerForm(activeStep - 1)) {
                handleStepComplete(activeStep);
              }
            }else if (activeStep === flightData!.passengers.length + 1 && validateContactForm()) {
              handleStepComplete(flightData!.passengers.length + 1);
            } else if (activeStep === flightData!.passengers.length + 2 && validateForm("invoice")) {
              reserveFlight();
            } else if (activeStep === totalSteps) {
              if (validatePaymentForm()) {
                handlePayment();
              }
            }
          }}
          className="w-full bg-secondary-6000 hover:bg-secondary-700 text-white font-semibold py-4 px-6 rounded-xl transition-all transform hover:scale-[1.01] active:scale-[0.98] shadow-lg hover:shadow-xl"
        >
          {activeStep === totalSteps ? "Ödemeyi Tamamla" : "Devam Et"}
        </button>
      </div>
    )
  }

  const reserveFlight = async () => {
    if (validateForm("passenger")) {
      try {
        const organizedData = organizeFormData(formData);
        setIsLoading(true);
        var response = await axios.post<FlightReservationResponse>("/api/flight/reservation", {
        ShoppingCartId: flightData!.shoppingCartId,
        Contact:{
          Email: organizedData.contactInfo.email,
          Phone: organizedData.contactInfo.phone,
          Name: organizedData.contactInfo.name,
          LastName: organizedData.contactInfo.surname
        },
        Passengers: organizedData.passengers.map((x) => ({
          FirstName: x.name,
          LastName: x.surname,
          BirthDate: parseBirthDate(x.birthDate),
          CitizenNo: x.identityNumber,
          Nationality: x.nationality,
          PassportNo: x.passportNumber,
          PassportExpireDate: x.passportExpiry !== "" ? parseBirthDate(x.passportExpiry) : new Date().toISOString(),
          Gender: x.gender,
          Email: organizedData.contactInfo.email,
          Phone: organizedData.contactInfo.phone,
          PaxType: x.paxType,
          PaxReferanceId: x.paxReferenceId,
          PaxReferenceId: x.paxReferenceId,
          isChild: x.paxType !== 'ADT'
        })),
      });
      setIsLoading(false);
      if (!response.data.hasError){
        flightData!.isPrebooked = true;
        flightData!.installmentOptions = response.data.shoppingCart.paymentOption.installmentOptions;
        flightData!.totalAmount = response.data.shoppingCart.totalAmount;
        flightData!.allocateTimestamp = new Date(response.data.shoppingCart.createdDateTime).getTime();
        
        calculateTotalPrice();
        setFlightData(flightData);

        // URL'ye ShoppingCartId parametresini ekle
        const url = new URL(window.location.href);
        url.searchParams.set('ShoppingCartId', flightData!.shoppingCartId);
        window.history.replaceState({}, '', url.toString());

        // Ödeme adımına geçildiğini işaretle
        setIsPaymentStep(true);
        handleStepComplete(flightData!.passengers.length + 2);
        
      }else{
        toast.error(response.data.errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Rezervasyon işlemi sırasında hata:", error);
      setGeneralError("Rezervasyon işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
      setIsLoading(false);
      toast.error("Rezervasyon işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  }
}

  const renderMain = () => {
    if (!flightData?.isAllocated) {
      return (
        <div className="fixed inset-0 bg-white/95 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-3xl p-10 shadow-xl max-w-xl w-full mx-4">
            <div className="flex items-center justify-between mb-6">
              <div className="flex flex-col items-center space-y-2">
                <i className="las la-plane-departure text-6xl text-neutral-500"></i>
                <span className="text-xl font-medium text-neutral-500">
                  {flightData?.selectedDepartFlight!.flightLocations.departureLocationCode}
                </span>
              </div>

              <div className="w-64 mx-12 relative">
                <div className="absolute top-1/2 left-0 right-0">
                  <div className="h-1 w-full bg-neutral-100 rounded">
                    <div className="h-1 bg-neutral-500 rounded animate-progress-soft"></div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center space-y-2">
                <i className="las la-plane-arrival text-6xl text-neutral-500"></i>
                <span className="text-xl font-medium text-neutral-500">
                  {flightData?.selectedDepartFlight!.flightLocations.arrivalLocationCode}
                </span>
              </div>
            </div>

            <div className="text-center space-y-6">
              <h3 className="text-xl font-medium text-neutral-800">
                Yönlendiriliyorsunuz
              </h3>
              <div className="flex justify-center">
                <div className="w-12 h-12 border-4 border-neutral-200 border-t-neutral-500 rounded-full animate-spin"></div>
              </div>
              <p className="text-md text-neutral-500">
                Bu işlem birkaç saniye sürebilir, lütfen bekleyin.
              </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="w-full flex flex-col sm:rounded-2xl space-y-8">
        {/* Yolcu Akordiyon Adımları */}
        {flightData.passengers.map((passenger, index) => (
          <CheckoutAccordion
            key={index}
            title={`${index + 1}. ${getPassengerTypeName(passenger.paxType)} Bilgileri`}
            isActive={activeStep === index + 1}
            isCompleted={isStepCompleted(index + 1)}
            isDisabled={isStepDisabled(index + 1)}
            stepNumber={index + 1}
            onToggle={() => handleStepToggle(index + 1)}
          >
            <div className="mb-1">
              <div className="flex justify-between items-center mb-5">
                {savedPassengers.length > 0 && (
                  <div className="flex-1 max-w-xs">
                    <select
                      className={`w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 ${
                        formErrors[`passengerName${index}`] ? 'border-red-500' : ''
                      }`}
                      onChange={(e) => handleSavedPassengerSelect(index, e.target.value)}
                      value={selectedPassengers[index] || ""}
                    >
                      <option value="">Kayıtlı Yolcu Seç</option>
                      {savedPassengers.map((savedPassenger) => {
                        const isSelected = Object.values(selectedPassengers).includes(savedPassenger.id.toString());
                        return (
                          <option 
                            key={savedPassenger.id} 
                            value={savedPassenger.id}
                            disabled={isSelected && selectedPassengers[index] !== savedPassenger.id.toString()}
                          >
                            {savedPassenger.firstName} {savedPassenger.lastName} {isSelected ? '(Seçildi)' : ''}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>
              <div className="space-y-5">
                <div className="flex space-x-5">
                  {renderInput(`passengerName${index}`, "Ad", "Ad")}
                  {renderInput(`passengerSurname${index}`, "Soyad", "Soyad")}
                </div>
                <div className="flex space-x-5">
                  {renderInput(
                    `passengerBirthDate${index}`,
                    "Doğum Tarihi",
                    "GG/AA/YYYY"
                  )}
                  <div className="flex-1 space-y-1">
                    <Label>Uyruk</Label>
                    <Select
                      name={`passengerNationality${index}`}
                      value={formData[`passengerNationality${index}`]}
                      onChange={handleInputChange}
                      className="border-neutral-200 focus:border-neutral-200 focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-200 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                      disabled={!paymentInputsDisabled}
                    >
                      <option value="">Uyruk Seç</option>
                      <option value="TR">Türkiye</option>
                      <option value="OTHER">Diğer Ülkeler</option>
                    </Select>
                  </div>
                </div>
                {(formData[`passengerNationality${index}`] !== "" && formData[`passengerNationality${index}`] !== undefined) && (
                <div className="flex space-x-5">
                  {formData[`passengerNationality${index}`] === "TR" ? 
                    renderInput(
                      `passengerIdentityNumber${index}`,
                      "T.C. Kimlik Numarası",
                      "T.C. Kimlik Numarası"
                    ) : null}
                  {!flightData!.selectedDepartFlight!.flightLocations.arrivalLocation.includes("Türkiye") || formData[`passengerNationality${index}`] !== "TR" ? (
                    <>
                      <div className="flex-1 space-y-1">
                        <Label>Pasaport Numarası</Label>
                        <Input
                          type="text"
                          name={`passengerPassportNumber${index}`}
                          placeholder={
                            formErrors[`passengerPassportNumber${index}`]
                              ? "Lütfen bu alanı doldurun."
                              : "Pasaport Numarası"
                          }
                          value={formData[`passengerPassportNumber${index}`] || ""}
                          onChange={handleInputChange}
                          className={
                            formErrors[`passengerPassportNumber${index}`]
                              ? "placeholder-red-400"
                              : ""
                          }
                        />
                      </div>
                      <div className="flex-1 space-y-1">
                        <Label>Pasaport S.K.T.</Label>
                        <Input
                          type="text"
                          name={`passengerPassportExpiry${index}`}
                          placeholder={
                            formErrors[`passengerPassportExpiry${index}`]
                              ? "Lütfen bu alanı doldurun."
                              : "GG/AA/YYYY"
                          }
                          value={formData[`passengerPassportExpiry${index}`] || ""}
                          onChange={handleInputChange}
                          className={
                            formErrors[`passengerPassportExpiry${index}`]
                              ? "placeholder-red-400"
                              : ""
                          }
                        />
                      </div>
                    </>
                  ) : null}
                  <div className="flex-1 space-y-1">
                    <Label>Cinsiyet</Label>
                    <Select
                      name={`passengerGender${index}`}
                      value={formData[`passengerGender${index}`] || ""}
                      onChange={handleInputChange}
                      className={formErrors[`passengerGender${index}`] ? "text-red-400" : ""}
                      disabled={!paymentInputsDisabled}
                    >
                      <option value="" className={formErrors[`passengerGender${index}`] ? "text-red-400" : ""}>
                        {formErrors[`passengerGender${index}`] ? "Lütfen bu alanı doldurun." : "Cinsiyet"}
                      </option>
                      <option value="male">Erkek</option>
                      <option value="female">Kadın</option>
                    </Select>
                  </div>
                </div>
                )}
              </div>
            </div>
            {renderContinueButton()}
          </CheckoutAccordion>
        ))}

        {/* İletişim Bilgileri Akordionu */}
        <CheckoutAccordion
          title="İletişim Bilgileri"
          isActive={activeStep === flightData.passengers.length + 1}
          isCompleted={isStepCompleted(flightData.passengers.length + 1)}
          isDisabled={isStepDisabled(flightData.passengers.length + 1)}
          stepNumber={flightData.passengers.length + 1}
          onToggle={() => handleStepToggle(flightData.passengers.length + 1)}
        >
          {renderContactInfo()}
        </CheckoutAccordion>

        {/* Fatura Bilgileri Akordionu */}
        <CheckoutAccordion
          title="Fatura Bilgileri"
          isActive={activeStep === flightData.passengers.length + 2}
          isCompleted={isStepCompleted(flightData.passengers.length + 2)}
          isDisabled={isStepDisabled(flightData.passengers.length + 2)}
          stepNumber={flightData.passengers.length + 2}
          onToggle={() => handleStepToggle(flightData.passengers.length + 2)}
        >
          {renderInvoiceInfo()}
        </CheckoutAccordion>

        {/* Ödeme Bilgileri Akordionu */}
        <CheckoutAccordion
          title="Ödeme Bilgileri"
          isActive={activeStep === totalSteps}
          isCompleted={isStepCompleted(totalSteps)}
          isDisabled={isStepDisabled(totalSteps)}
          stepNumber={totalSteps}
          onToggle={() => handleStepToggle(totalSteps)}
        >
          {renderPaymentInfo()}
        </CheckoutAccordion>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="sticky top-32">
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 px-0 sm:p-6 xl:p-8">
          {/* Uçuş Detayları */}
          {flightData?.selectedDepartFlight && (
            <>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-start">
                    <span className="text-2xl font-semibold">{flightData.selectedDepartFlight.flightLocations.departureLocationCode}</span>
                    <span className="text-sm text-neutral-500">{getLocationWithoutParentheses(flightData.selectedDepartFlight.flightLocations.departureLocation)}</span>
                  </div>
                  <div className="flex flex-col items-center px-4">
                    <div className="w-32 h-px bg-neutral-300 relative">
                      <div className="absolute -top-[24px] right-[45%] transform rotate-90">
                        <i className="las la-plane text-lg text-neutral-500"></i>
                      </div>
                    </div>
                    <span className="text-xs text-neutral-500 mt-2">{flightData.selectedDepartFlight.flightInfo.segment[0].flightDuration}</span>
                  </div>
                  <div className="flex flex-col items-end">
                    <span className="text-2xl font-semibold">{flightData.selectedDepartFlight.flightLocations.arrivalLocationCode}</span>
                    <span className="text-sm text-neutral-500">{getLocationWithoutParentheses(flightData.selectedDepartFlight.flightLocations.arrivalLocation)}</span>
                  </div>
                </div>

                <div className="flex items-center justify-between text-sm text-neutral-500 border-t border-dashed pt-4">
                  <div className="flex items-center space-x-2">
                    <i className="las la-calendar text-lg"></i>
                    <span>{formatDateObject(flightData.selectedDepartFlight.flightDates.departureDate)}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <i className="las la-user text-lg"></i>
                    <span>{flightData.selectedDepartFlight.flightGuests.totalGuests} Yolcu</span>
                  </div>
                </div>

                <div className="flex items-center space-x-3 text-sm text-neutral-500">
                  <img 
                    src={`/images/airlines/${flightData.selectedDepartFlight.flightInfo.segment[0].airlineName}.png`} 
                    alt="Airline" 
                    className="h-6 w-auto object-contain"
                  />
                  <span>{flightData.selectedDepartFlight.flightInfo.segment[0].flightCode}</span>
                  <span>·</span>
                  <span>{flightData.selectedDepartFlight.flightClass || "Economy"}</span>
                </div>
              </div>

              {flightData.selectedReturnFlight && (
                <>
                  <div className="border-t border-neutral-200 dark:border-neutral-700 my-4"></div>
                  <div className="space-y-4">
                    {/* Return flight details */}
                  </div>
                </>
              )}
            </>
          )}

          {/* Ödeme Özeti */}
          <div className="border-t border-neutral-200 dark:border-neutral-700 pt-6">
            <div className="space-y-3">
              <div className="flex justify-between text-lg">
                <span className="font-medium">Toplam Tutar</span>
                <span className="font-semibold text-secondary-6000">{totalPrice},{totalDecimal} TL</span>
              </div>
            </div>
          </div>

          {/* Süre Bilgisi */}
          <div className="space-y-3">
            <div className="bg-neutral-50 rounded-xl p-4">
              <div className="flex items-center justify-center space-x-2 text-2xl font-semibold text-secondary-6000">
                {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
              </div>
              <p className="text-sm text-neutral-500 text-center mt-2">
                TODOGO bu koltuğu sizin için ayırdı. Lütfen size ayırılmış süre içerisinde ödeme yapınız.
              </p>
            </div>
          </div>

          {renderContinueButton()}
        </div>
      </div>
    );
  };

  const renderPaymentInfo = () => {
    return (
      <div className="space-y-6">
        <div className="space-y-6">
          <div className="space-y-5">
            <div className="flex space-x-5">
              <div className="flex-1 space-y-1">
                <Label>Kart Üzerindeki İsim</Label>
                <Input
                  type="text"
                  name="cardHolder"
                  placeholder={cardErrors.cardHolder || "Kart Üzerindeki İsim"}
                  value={cardInfo.cardHolder}
                  onChange={handleCardInputChange}
                  className={cardErrors.cardHolder ? "placeholder-red-400" : ""}
                  disabled={!paymentInputsDisabled}
                />
              </div>
            </div>
            <div className="flex space-x-5">
              <div className="flex-1 space-y-1">
                <Label>Kart Numarası</Label>
                <Input
                  type="text"
                  name="cardNumber"
                  placeholder={cardErrors.cardNumber || "Kart Numarası"}
                  value={cardInfo.cardNumber}
                  onChange={handleCardInputChange}
                  className={cardErrors.cardNumber ? "placeholder-red-400" : ""}
                  disabled={!paymentInputsDisabled}
                />
              </div>
            </div>
            <div className="flex space-x-5">
              <div className="flex-1 space-y-1">
                <Label>Son Kullanma Tarihi</Label>
                <Input
                  type="text"
                  name="expiryDate"
                  placeholder={cardErrors.expiryDate || "AA/YY"}
                  value={cardInfo.expiryDate}
                  onChange={handleCardInputChange}
                  className={cardErrors.expiryDate ? "placeholder-red-400" : ""}
                  disabled={!paymentInputsDisabled}
                />
              </div>
              <div className="flex-1 space-y-1">
                <Label>CVV</Label>
                <Input
                  type="text"
                  name="cvv"
                  placeholder={cardErrors.cvv || "CVV"}
                  value={cardInfo.cvv}
                  onChange={handleCardInputChange}
                  className={cardErrors.cvv ? "placeholder-red-400" : ""}
                  disabled={!paymentInputsDisabled}
                />
              </div>
            </div>
            {installmentOptions.length > 0 && (
              <div className="flex-1 space-y-1">
                <Label>Taksit Seçenekleri</Label>
                <Select
                  name="installmentId"
                  value={cardInfo.installmentId}
                  onChange={(e) => setCardInfo(prev => ({ ...prev, installmentId: e.target.value }))}
                  className="nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                  disabled={!paymentInputsDisabled}
                >
                  {installmentOptions.map((option) => (
                    <option key={option.installmentOptionId} value={option.installmentOptionId}>
                      {option.installmentCount === 1
                        ? "Tek Çekim"
                        : `${option.installmentCount} Taksit - Aylık ${(option.monthlyPayment).toFixed(2)} TL`}
                    </option>
                  ))}
                </Select>
              </div>
            )}
            <div className="flex items-start space-x-4 mt-6">
              <Checkbox
                defaultChecked={isTermsAccepted}
                onChange={handleTermsChange}
                name="terms"
                className={formErrors.terms ? "border-red-400" : ""}
              />
              <div className="text-sm">
                <span className="text-neutral-500">
                  <a href="/terms" target="_blank" className="text-secondary-6000 hover:text-secondary-500">Mesafeli Satış Sözleşmesi</a>'ni ve{" "}
                  <a href="/privacy" target="_blank" className="text-secondary-6000 hover:text-secondary-500">Gizlilik Politikası</a>'nı okudum, onaylıyorum.
                </span>
              </div>
            </div>
          </div>
          <div className="flex space-x-4">
            <img
              src={paymentCardsImage}
              alt="Kabul edilen ödeme yöntemleri"
              className="h-8 object-contain"
            />
          </div>
        </div>
        {renderContinueButton()}
      </div>
    );
  };

  const renderContactInfo = () => {
    return (
      <div>
        <div className="space-y-5">
          <div className="flex space-x-5">
            {renderInput("contactName", "Ad", "Ad")}
            {renderInput("contactSurname", "Soyad", "Soyad")}
          </div>
          <div className="flex space-x-5">
            <div className="flex-1 space-y-1">
              <Label>Telefon Numarası</Label>
              <div className="flex relative w-100">
                <IntlTelInput
                  ref={intlTelInputRef}
                  initialValue={countryCode}
                  onChangeNumber={setPhoneValue}
                  onChangeValidity={setIsPhoneValid}
                  onChangeCountry={(e) => {
                    setTimeout(() => {
                      const searchInput = document.querySelector('.iti__search-input') as HTMLInputElement;
                      if (searchInput) {
                        searchInput.placeholder = "Ara";
                      }
                    }, 0);
                  }}
                  onChangeErrorCode={(e) => {
                    if (formErrors['contactPhone'] && e !== null) {
                      const input = document.querySelector('.iti__tel-input');
                      input?.setAttribute('placeholder', e == 2 ? "Lütfen bu alanı doldurun." : errorMap[e]);
                      input?.classList.add('placeholder-red-400');
                    }
                  }}
                  initOptions={{
                    initialCountry: "tr",
                    separateDialCode: true,
                    strictMode: true,
                    loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
                    dropdownContainer: document.body
                  }}
                  disabled={!paymentInputsDisabled}
                />
              </div>
            </div>
            {renderInput("contactEmail", "E-posta Adresi", "E-posta Adresi")}
          </div>
          <div className="text-sm text-neutral-500">
            Bilet bilgileriniz ve uçuş detaylarınız, ücretsiz SMS ve e-posta
            ile tarafınıza iletilecektir.
          </div>
          
          {/* Devam Et Butonu */}
          {renderContinueButton()}
        </div>
      </div>
    );
  };

  const renderInvoiceInfo = () => {
    return (
      <div>
        <div className="space-y-5">
          <div className="flex space-x-5">
            <div className="flex-1 space-y-1">
              <Label>Fatura Tipi</Label>
              <Select
                name="invoiceType"
                value={formData.invoiceType || "Bireysel"}
                onChange={handleInputChange}
                className="nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                disabled={!paymentInputsDisabled}
              >
                <option value="Bireysel">Bireysel</option>
                <option value="Kurumsal">Kurumsal</option>
              </Select>
            </div>
            {renderInput("invoiceCity", "İl", "İl")}
          </div>
          <div className="flex space-x-5">
            {renderInput("billingName", formData.invoiceType === "Kurumsal" ? "Firma Adı" : "Ad Soyad", formData.invoiceType === "Kurumsal" ? "Firma Adı" : "Ad Soyad")}
            {renderInput("invoiceDistrict", "İlçe", "İlçe")}
            {renderInput("invoiceAddress", "Adres", "Adres")}
          </div>
          {formData.invoiceType === "Kurumsal" && (
            <div className="flex space-x-5">
              {renderInput("taxOffice", "Vergi Dairesi", "Vergi Dairesi")}
              {renderInput("taxNumber", "Vergi Numarası", "Vergi Numarası")}
            </div>
          )}
          {renderContinueButton()}
        </div>
      </div>
    );
  };

  const handleSavedPassengerSelect = (passengerIndex: number, savedPassengerId: string) => {
    if (savedPassengerId === "") {
      // Seçimi kaldırıldığında ilgili yolcunun tüm bilgilerini temizle
      const newSelectedPassengers = { ...selectedPassengers };
      delete newSelectedPassengers[passengerIndex];
      setSelectedPassengers(newSelectedPassengers);

      const newFormData = { ...formData };
      const fieldsToDelete = [
        `passengerName${passengerIndex}`,
        `passengerSurname${passengerIndex}`,
        `passengerBirthDate${passengerIndex}`,
        `passengerGender${passengerIndex}`,
        `passengerNationality${passengerIndex}`,
        `passengerIdentityNumber${passengerIndex}`,
        `passengerPassportNumber${passengerIndex}`,
        `passengerPassportExpiry${passengerIndex}`
      ];

      fieldsToDelete.forEach(field => {
        delete newFormData[field];
      });

      // Eğer ilk yolcu ise iletişim bilgilerini de temizle
      if (passengerIndex === 0) {
        delete newFormData.contactName;
        delete newFormData.contactSurname;
        delete newFormData.contactEmail;
        setPhoneValue("");
        setPendingPhoneNumber(null);
      }

      setFormData(newFormData);
      return;
    }

    // Seçilen yolcunun başka bir yerde seçili olup olmadığını kontrol et
    const isPassengerAlreadySelected = Object.values(selectedPassengers).includes(savedPassengerId);
    if (isPassengerAlreadySelected) {
      toast.error("Bu yolcu başka bir yolcu için zaten seçilmiş.", {
        position: "top-right",
        autoClose: 3000
      });
      return;
    }

    const savedPassenger = savedPassengers.find(p => p.id === parseInt(savedPassengerId));
    if (!savedPassenger) return;

    setSelectedPassengers(prev => ({
      ...prev,
      [passengerIndex]: savedPassengerId
    }));

    const birthDate = new Date(savedPassenger.dateOfBirth);
    const formattedBirthDate = `${birthDate.getDate().toString().padStart(2, '0')}/${(birthDate.getMonth() + 1).toString().padStart(2, '0')}/${birthDate.getFullYear()}`;

    const newFormData = {
      ...formData,
      [`passengerName${passengerIndex}`]: savedPassenger.firstName,
      [`passengerSurname${passengerIndex}`]: savedPassenger.lastName,
      [`passengerBirthDate${passengerIndex}`]: formattedBirthDate,
      [`passengerGender${passengerIndex}`]: savedPassenger.gender === 1 ? "male" : "female",
      [`passengerNationality${passengerIndex}`]: savedPassenger.nationality,
      [`passengerIdentityNumber${passengerIndex}`]: savedPassenger.identityNumber,
    };

    if (passengerIndex === 0) {
      newFormData.contactName = savedPassenger.firstName;
      newFormData.contactSurname = savedPassenger.lastName;
      newFormData.contactEmail = savedPassenger.email || "";
      if (savedPassenger.phoneNumber) {
        setPendingPhoneNumber(savedPassenger.phoneNumber);
      }
    }

    setFormData(newFormData);

    // Başarılı seçim mesajı göster
    toast.success(`${savedPassenger.firstName} ${savedPassenger.lastName} bilgileri başarıyla yüklendi.`, {
      position: "top-right",
      autoClose: 2000
    });
  };

  const validatePassengerForm = (passengerIndex: number): boolean => {
    const newErrors: { [key: string]: boolean } = {};
    let isValid = true;
    const requiredFields = [
      { key: "Name", label: "Ad" },
      { key: "Surname", label: "Soyad" },
      { key: "BirthDate", label: "Doğum Tarihi" },
      { key: "Gender", label: "Cinsiyet" },
      { key: "Nationality", label: "Uyruk" }
    ];

    // Temel alanların kontrolü
    for (const field of requiredFields) {
      const key = `passenger${field.key}${passengerIndex}`;
      if (!formData[key] || formData[key].trim() === "") {
        newErrors[key] = true;
        isValid = false;
      }
    }

    // Uyruk bazlı kontroller
    if (formData[`passengerNationality${passengerIndex}`] === "TR") {
      const key = `passengerIdentityNumber${passengerIndex}`;
      if (!formData[key] || formData[key].length !== 11) {
        newErrors[key] = true;
        isValid = false;
      }
    } else if (formData[`passengerNationality${passengerIndex}`] === "OTHER") {
      const passportKey = `passengerPassportNumber${passengerIndex}`;
      const expiryKey = `passengerPassportExpiry${passengerIndex}`;

      if (!formData[passportKey] || formData[passportKey].length !== 9) {
        newErrors[passportKey] = true;
        isValid = false;
      }
      if (!formData[expiryKey] || formData[expiryKey].length !== 10) {
        newErrors[expiryKey] = true;
        isValid = false;
      }
    }

    if (!isValid) {
      let errorMessage = `Lütfen ${passengerIndex + 1}. yolcu için aşağıdaki alanları kontrol ediniz:\n`;
      Object.keys(newErrors).forEach(key => {
        let fieldLabel = "";
        if (key.includes("Name")) fieldLabel = "Ad";
        else if (key.includes("Surname")) fieldLabel = "Soyad";
        else if (key.includes("BirthDate")) fieldLabel = "Doğum Tarihi";
        else if (key.includes("Gender")) fieldLabel = "Cinsiyet";
        else if (key.includes("Nationality")) fieldLabel = "Uyruk";
        else if (key.includes("IdentityNumber")) fieldLabel = "T.C. Kimlik No";
        else if (key.includes("PassportNumber")) fieldLabel = "Pasaport No";
        else if (key.includes("PassportExpiry")) fieldLabel = "Pasaport S.K.T.";

        errorMessage += `• ${fieldLabel}\n`;
      });

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          whiteSpace: 'pre-line'
        }
      });
    }

    setFormErrors(prev => ({ ...prev, ...newErrors }));
    return isValid;
  };

  const validateContactForm = (): boolean => {
    const newErrors: { [key: string]: boolean } = {};
    let isValid = true;

    ["contactName", "contactSurname", "contactPhone", "contactEmail"].forEach(
      (key) => {
        if (!formData[key]) {
          newErrors[key] = true;
          isValid = false;
        }
      }
    );

    if (!isValidEmail(formData.contactEmail)) {
      newErrors.contactEmail = true;
      isValid = false;
    }

    if (!isPhoneValid) {
      newErrors.contactPhone = true;
      isValid = false;
    }

    if (!isValid) {
      let errorMessage = "Lütfen aşağıdaki alanları kontrol ediniz:\n";
      Object.keys(newErrors).forEach(key => {
        const fieldLabels: { [key: string]: string } = {
          'contactName': 'İletişim - Ad',
          'contactSurname': 'İletişim - Soyad',
          'contactPhone': 'İletişim - Telefon',
          'contactEmail': 'İletişim - E-posta'
        };
        errorMessage += `• ${fieldLabels[key]}\n`;
      });

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          whiteSpace: 'pre-line'
        }
      });
    }

    setFormErrors(prev => ({ ...prev, ...newErrors }));
    return isValid;
  };

  const validateInvoiceForm = (): boolean => {
    const newErrors: { [key: string]: boolean } = {};
    let isValid = true;

    ["invoiceCity", "invoiceDistrict", "invoiceAddress", "billingName"].forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = true;
        isValid = false;
      }
    });

    if (formData.invoiceType === "Kurumsal") {
      if (!formData.taxNumber || formData.taxNumber.length !== 10) {
        newErrors.taxNumber = true;
        isValid = false;
      }
      if (!formData.taxOffice) {
        newErrors.taxOffice = true;
        isValid = false;
      }
    }

    ["invoiceCity", "invoiceDistrict", "taxOffice"].forEach((field) => {
      if (formData[field] && !isOnlyLetters(formData[field])) {
        newErrors[field] = true;
        isValid = false;
      }
    });

    if (!isValid) {
      let errorMessage = "Lütfen aşağıdaki alanları kontrol ediniz:\n";
      Object.keys(newErrors).forEach(key => {
        const fieldLabels: { [key: string]: string } = {
          'invoiceCity': 'Fatura - İl',
          'invoiceDistrict': 'Fatura - İlçe',
          'invoiceAddress': 'Fatura - Adres',
          'billingName': 'Fatura - Ad Soyad/Firma Adı',
          'taxNumber': 'Fatura - Vergi No',
          'taxOffice': 'Fatura - Vergi Dairesi'
        };
        errorMessage += `• ${fieldLabels[key]}\n`;
      });

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          whiteSpace: 'pre-line'
        }
      });
    }

    setFormErrors(prev => ({ ...prev, ...newErrors }));
    return isValid;
  };

  const validatePaymentForm = (): boolean => {
    const newErrors: { [key: string]: boolean } = {};
    const newCardErrors = { ...cardErrors };
    let isValid = true;

    if (cardInfo.cardNumber.replace(/\s/g, "").length !== 16) {
      newErrors.cardNumber = true;
      newCardErrors.cardNumber = "Lütfen geçerli bir kart numarası girin.";
      isValid = false;
    }

    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardInfo.expiryDate)) {
      newErrors.expiryDate = true;
      newCardErrors.expiryDate = "Lütfen geçerli bir son kullanma tarihi girin.";
      isValid = false;
    }

    if (cardInfo.cvv.length < 3 || cardInfo.cvv.length > 4) {
      newErrors.cvv = true;
      newCardErrors.cvv = "Lütfen geçerli bir CVV girin.";
      isValid = false;
    }

    if (!isTermsAccepted) {
      newErrors.terms = true;
      isValid = false;
    }

    if (!isValid) {
      let errorMessage = "Lütfen aşağıdaki alanları kontrol ediniz:\n";
      Object.keys(newErrors).forEach(key => {
        const fieldLabels: { [key: string]: string } = {
          'cardNumber': 'Kart Numarası',
          'expiryDate': 'Son Kullanma Tarihi',
          'cvv': 'CVV',
          'terms': 'Sözleşme ve Koşullar'
        };
        errorMessage += `• ${fieldLabels[key]}\n`;
      });

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          whiteSpace: 'pre-line'
        }
      });
    }

    setFormErrors(prev => ({ ...prev, ...newErrors }));
    setCardErrors(newCardErrors);
    return isValid;
  };

  useEffect(() => {
    const loadSavedPassengers = async () => {
      try {
        const currentUser = getCurrentUser();
        if (currentUser) {
          const passengers = await getSavedPassengers();
          setSavedPassengers(passengers);
        }
      } catch (error) {
        console.error('Kayıtlı yolcular yüklenirken hata:', error);
      }
    };

    loadSavedPassengers();
  }, []);

  useEffect(() => {
    if (pendingPhoneNumber && intlTelInputRef.current) {
      setPhoneValue(pendingPhoneNumber);
      intlTelInputRef.current.getInstance().setNumber(pendingPhoneNumber);
      setPendingPhoneNumber(null);
    }
  }, [pendingPhoneNumber, activeStep]);

  return (
    <div className="nc-CheckOutPagePageMain">
      <main className={`container mt-24 mb-24 flex flex-col lg:flex-row ${isLoading ? "opacity-50 pointer-events-none" : ""}`}>
        {isLoading && <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status">
        </div>}
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 mb-10 lg:mb-0">
          {renderMain()}
        </div>
        <div className={`w-full lg:w-2/5 xl:w-1/3 ${paymentClassName}`}>
          {renderSidebar()}
        </div>
      </main>
      {renderTimeoutModal()}
      <ToastContainer stacked />
    </div>
  );
};

export default CheckOutPagePageMain;