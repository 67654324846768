import { FC } from "react";
import FAQCard, { FAQCardProps } from "components/FrequentlyAskedQuestionsCard/FrequentlyAskedQuestionsCard";
import { useTranslation } from 'react-i18next';

export interface SectionGridFilterCardProps {
  className?: string;
}

const FrequentlyAskedQuestions: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const { t } = useTranslation('home');

  const DEMO_DATA: FAQCardProps["data"][] = [
    {
      id: "FAQ1",
      answer: t('faq.answer1'),
      question: t('faq.question1'),

    },
    {
      id: "FAQ2",
      answer: t('faq.answer2'),
      question: t('faq.question2'),

    },
    {
      id: "FAQ3",
      answer: t('faq.answer3'),
      question: t('faq.question3'),

    },
    {
      id: "FAQ4",
      answer: t('faq.answer4'),
      question: t('faq.question4'),

    },
    {
      id: "FAQ5",
      answer: t('faq.answer5'),
      question: t('faq.question5'),

    },
    {
      id: "FAQ6",
      answer: t('faq.answer6'),
      question: t('faq.question6'),

    },
    {
      id: "FAQ7",
      answer: t('faq.answer7'),
      question: t('faq.question7'),
    },
  ];

  return (
    <div
      id="faq"
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="justify-center text-center mt-40">
        <h1 className="text-3xl md:text-4xl font-semibold">{t('faq.title')}</h1>
        <p className="faqSubTitle mt-2 md:mt-5 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
          {t('faq.subtitle')}
        </p>
      </div>
      <div className="mb-8 lg:mb-11">
      </div>
      <div className="lg:p-10 grid grid-cols-1 gap-6 rounded-3xl">
        {DEMO_DATA.map((item, index) => (
          <FAQCard key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
