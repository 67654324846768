import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Heading2 from "components/Heading/Heading2";
import { CheckCircleIcon, HomeIcon } from '@heroicons/react/24/solid';
import { motion, Variants } from 'framer-motion';
import { FlightCompleteResponse } from "types/flight.types";

export interface PayPageProps {
  className?: string;
}

interface FlightCardProps {
  segments: Array<any>;
  type: string;
}



const formatDateWithoutSeconds = (date:Date) => {
  return date.toLocaleString("tr-TR", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).replaceAll(".","/");
};

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ticketingCompleted, setTicketingCompleted] = useState<boolean | null>(null);
  const [billingCompleted, setBillingCompleted] = useState<boolean | null>(null);
  const [shoppingCart, setShoppingCart] = useState<any | null>(null);
  const [finalizeError, setFinalizeError] = useState<string | null>(null);
  const [billingError, setBillingError] = useState<string | null>(null);
  const [logoError, setLogoError] = useState<{[key: string]: boolean}>({});
  
  const renderAirlineLogo = (airlineName: string) => {
    if (logoError[airlineName]) {
      // Logo yüklenemediğinde gösterilecek fallback içerik
      return (
        <div className="flex items-center justify-center h-[60px]">
          <span className="text-neutral-500">{airlineName}</span>
        </div>
      );
    }
  
    try {
      return (
        <img 
          src={`/images/airlines/${airlineName}.png`}
          style={{width: "100%", height: "60px", objectFit: "contain"}}
          alt={airlineName}
          onError={() => setLogoError(prev => ({ ...prev, [airlineName]: true }))}
        />
      );
    } catch (error) {
      // require() hatası durumunda
      if (!logoError[airlineName]) {
        setLogoError(prev => ({ ...prev, [airlineName]: true }));
      }
      return (
        <div className="flex items-center justify-center h-[60px]">
          <span className="text-neutral-500">{airlineName}</span>
        </div>
      );
    }
  }

  const query = new URLSearchParams(location.search);
  const shoppingCartId = query.get('ShoppingCartId');

  const FlightCard: FC<FlightCardProps> = ({ segments, type }) => {
    return (
      <>
        <h3 className="text-1xl font-semibold">{type} Uçuşu</h3>
        <div className="nc-FlightCard group p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden">
          {segments.map((segment: any, index: number) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <div className="my-4 border-t border-neutral-200 dark:border-neutral-700 pt-4"></div>
              )}
              <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
                <div className="w-24 flex-shrink-0">
                  {renderAirlineLogo(segment.markettingAirlineName)}
                </div>
                <div className="flex-grow grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <div>
                    <div className="font-medium">{segment.markettingAirlineName}</div>
                    <div className="text-sm text-neutral-500">{segments.length == 1 ? "Direkt Uçuş" : "Aktarmalı Uçuş"}</div>
                  </div>
                  <div>
                    <div className="font-medium">{segment.departureAirportCode} - {segment.arrivalAirportCode}</div>
                    <div className="text-sm text-neutral-500">{segment.flightNumber}</div>
                  </div>
                  <div>
                    <div className="font-medium">{formatDateWithoutSeconds(new Date(segment.departureDateTime))} - {formatDateWithoutSeconds(new Date(segment.arrivalDateTime))}</div>
                    <div className="text-sm text-neutral-500">{Math.floor(segment.journeyDurationInMinute / 60)} saat {segment.journeyDurationInMinute % 60} dakika</div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };  
  
  const formatPrice = (price: number): [string, string] => {
    const [integerPart, decimalPart] = price.toFixed(2).split(".");
    return [integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "."), decimalPart];
  };

  useEffect(() => {
    // Başarılı ödeme sonrası mevcut işlemleri yap
    axios.get<FlightCompleteResponse>(`/api/flight/complete/${shoppingCartId}`)
      .then(response => {
        if (!response.data || !response.data.shoppingCart) {
          setErrorMessage("Bilet bilgileri alınamadı. Lütfen bizimle iletişime geçiniz.");
          console.error("Beklenmeyen bir hata oluştu:", response.data);
          setTicketingCompleted(false);
          return;
        }
        if (response.data.hasError) {
          setErrorMessage(response.data.errorMessage || "Bilet bilgileri alınamadı. Lütfen bizimle iletişime geçiniz.");
          console.error("Bilet bilgileri alınamadı:", response.data.errorMessage);
          setTicketingCompleted(false);
          return;
        }else{
          setBillingCompleted(response.data.isBilled);
          setTicketingCompleted(response.data.isFinalized);
          setShoppingCart(response.data.shoppingCart);

          if (response.data.isFinalized){
            setShoppingCart(response.data.shoppingCart);
          }
        }
      })
      .catch(error => {
        console.error("Error completing the flight:", error);
        setErrorMessage("Bilet bilgileri alınamadı. Lütfen bizimle iletişime geçiniz. ( " + error.response.data.error + " )");
        setTicketingCompleted(false);
      });
  }, [shoppingCartId]);

  const getLocationWithoutParentheses = (location: string | undefined) => {
    if (!location) return "";
    return location.replace(/\([^)]*\)/g, "").trim();
  };

  const checkIconVariants: Variants = {
    hidden: { 
      opacity: 0, 
      scale: 0.5,
      y: -50, // Yukarıdan başlaması için negatif y değeri
      rotate: 0
    },
    visible: { 
      opacity: 1, 
      scale: 1,
      y: 0, // Normal pozisyonuna gelme
      rotate: 360,
      transition: {
        type: "spring",
        stiffness: 100,
        delay: 0.5,
        damping: 15,
        duration: 1.5
      }
    }
  };

  const homeIconVariants: Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 1,
        delay: 1.5, 
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  const hoverRotate: Variants = {
    hover: {
      scale: 1.1,
      rotate: 360,
      transition: {
        duration: 2,
        type: "spring",
        stiffness: 50,
        damping: 10,
      }
    }
  };

  const renderContent = () => {
    if (ticketingCompleted === null) {
      return <div className="text-center">Yükleniyor...</div>;
    }

    if (ticketingCompleted === false) {
      return (
        <div className="text-center">
          <h2 className="mt-4 text-3xl font-bold text-red-600">
            {errorMessage}
            {finalizeError && <p className="mt-4 text-lg text-red-600">Biletleme hatası : {finalizeError}</p>}
            {/* {billingError && <p className="mt-4 text-lg text-red-600">Biletleme başarılı oldu ancak faturalandırmada hata oluştu : {billingError}</p>} */}
          </h2>
          <button
            onClick={() => navigate('/flight-checkout?ShoppingCartId=' + shoppingCartId)}
            className="mt-8 px-4 py-2 bg-blue-600 text-white rounded"
          >
            Tekrar Dene
          </button>
        </div>
      );
    }

    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8"
      >
        <div className="text-center">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={checkIconVariants}
            className="inline-block"
          >
            <CheckCircleIcon className="h-16 w-16 text-secondary-6000" />
          </motion.div>
          <h2 className="mt-4 text-3xl font-bold text-secondary-6000">
            Tebrikler! Ödemeniz Onaylandı.
          </h2>
          <h3 className="mt-4 text-center font-medium text-xl text-secondary-6000">
            {formatPrice(shoppingCart.totalAmount)[0]},{formatPrice(shoppingCart.totalAmount)[1]} TL
          </h3>
          <div className="mt-4 text-center font-medium text-xl text-neutral-500">
            <p>Bilet bilgileriniz ve uçuş detaylarınız, ücretsiz SMS ve e-posta ile tarafınıza iletilecektir.</p>
            <p className="mt-4">Lütfen aşağıda yer alan "Uçuş Bilgileri" ve "Bilet Bilgileri" bölümlerini kontrol ediniz ve bir problem olduğunu düşünüyorsanız, lütfen bizimle iletişime geçiniz.</p>
            <p className="mt-4">TODOGO.com'u tercih ettiğiniz için teşekkür eder, iyi yolculuklar dileriz.</p>
          </div>
          
          <motion.div 
            className="flex justify-center mt-8"
            initial="hidden"
            animate="visible"
            variants={homeIconVariants}
          >
            <motion.div
              whileHover="hover"
              whileTap={{ scale: 0.9 }}
              variants={hoverRotate}
              onClick={() => navigate('/')}
              className="cursor-pointer inline-block"
            >
              <HomeIcon className="w-16 h-16 text-secondary-6000" />
            </motion.div>
          </motion.div>
        </div>

        <div className="border-t border-neutral-200 dark:border-neutral-700 pt-8">
        </div>

        {renderPassengerInfo()}

        <h2 className="text-2xl font-semibold">Uçuş Bilgileri</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-6">
          {shoppingCart && (
            <>
              <div className="-mb-6">
                <Heading2
                  heading={
                    <div className="flex justify-center text-xl font-medium">
                      {`${getLocationWithoutParentheses(
                        shoppingCart.airBookings[0].flightSegments[0].departureAirportName
                      )} - ${getLocationWithoutParentheses(
                        shoppingCart.airBookings[0].flightSegments[shoppingCart.airBookings[0].flightSegments.length - 1].arrivalAirportName
                      )}`}
                    </div>
                  }
                  subHeading={
                    <span className="block items-center text-base text-md text-neutral-500 text-center mt-4">
                      <span>
                        {formatDateWithoutSeconds(new Date(shoppingCart.airBookings[0].flightSegments[0].departureDateTime))}
                        {shoppingCart.airBookings[0].flightSegments.filter((x:any) => x.directionInd == 1).length > 0 && 
                          ` - ${formatDateWithoutSeconds(new Date(shoppingCart.airBookings[0].flightSegments.filter((x:any) => x.directionInd == 1)[0].departureDateTime))}`}
                      </span>
                      <span className="mx-2">·</span>
                      {shoppingCart.airBookings[0].flightSegments.filter((x:any) => x.directionInd == 1).length > 0 ? "Gidiş - Dönüş" : "Tek Yön"}
                      {/* <span className="mx-2">·</span>
                      {flightData.selectedDepartFlight.flightClass} */}
                      <span className="mx-2">·</span>
                      {shoppingCart.passengers.length} Yolcu
                    </span>
                  }
                />
              </div>
              <FlightCard segments={shoppingCart.airBookings[0].flightSegments.filter((x:any) => x.directionInd == 0)} type="Gidiş" />
            </>
          )}
          {shoppingCart.airBookings[0].flightSegments.filter((x:any) => x.directionInd == 1).length > 0 && (
            <>
              <FlightCard segments={shoppingCart.airBookings[0].flightSegments.filter((x:any) => x.directionInd == 1)} type="Dönüş" />
            </>
          )}
        </div>

      </motion.div>
    );
  };

  const renderPassengerInfo = () => {
    if (!shoppingCart) {
      console.error("Gerekli veriler eksik");
      return null;
    }
  
    const renderFlightInfo = (direction: string) => {
      if (!shoppingCart) {
        console.error(`${direction} uçuş bilgileri eksik:`, shoppingCart);
        return null;
      }
  
      return (
        <>
          <h3 className="text-1xl font-semibold mt-6">{direction}</h3>
          {shoppingCart.passengers.map((passenger: any, passengerIndex: number) => (
            <div key={passengerIndex} className="nc-FlightCard group p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden mt-6">
              <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
                <div className="flex-grow grid grid-cols-1 sm:grid-cols-5 gap-4 text-center">
                  <div>
                    <div className="font-medium">Ad</div>
                    <div className="text-sm text-neutral-500">{passenger.firstName || 'N/A'}</div>
                  </div>
                  <div>
                    <div className="font-medium">Soyad</div>
                    <div className="text-sm text-neutral-500">{passenger.lastName || 'N/A'}</div>
                  </div>
                  <div>
                    <div className="font-medium">Bilet Numarası</div>
                    <div className="text-sm text-neutral-500">
                      {shoppingCart.airBookings[0].passengerBreakdowns[passengerIndex].ticketNumber || 'N/A'}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium">PNR</div>
                    <div className="text-sm text-neutral-500">
                      {shoppingCart.airBookings[0].bookingCode}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium">Bagaj Hakkı</div>
                    <div className="text-sm text-neutral-500">
                      {shoppingCart.airBookings[0].passengerBreakdowns[passengerIndex].baggageAllowance || 'Belirtilmemiş'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    };
  
    return (
      <div>
        <h3 className="text-2xl font-semibold">Bilet Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-9"></div>
        {renderFlightInfo("Yolcu Bilgileri")}
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-24 mb-24 flex flex-col-reverse lg:flex-row">
        <div className="w-full">
          {renderContent()}
        </div>
      </main>
    </div>
  );
};

export default PayPage;
