import React, { useState, useEffect, useRef } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonSubmit from "../ButtonSubmit";
import ButtonClear from "../ButtonClear";
import { useNavigate } from "react-router-dom";
import { SearchItem } from "types/common.types";
import { useTranslation } from "react-i18next";
export type TypeDropOffLocationType = "Gidiş ve Dönüş" | "Tek Yön";

const FlightSearchForm = ({formFlightData, onSubmit}:{formFlightData?:any, onSubmit?:()=>void}) => {
  const { t } = useTranslation('home');
  const locale = t('locale');
  const navigate = useNavigate();

  const [dropOffLocationType, setDropOffLocationType] =
    useState<TypeDropOffLocationType>("Tek Yön");

  const [fieldNameShow, setFieldNameShow] = useState<
    | ""
    | "locationPickup"
    | "locationDropoff"
    | "dates"
    | "guests"
    | "general"
    | "ticketClass"
  >("");

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setFieldNameShow("any" as any);
      }
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const [flightDirection, setFlightDirection] =
    useState<TypeDropOffLocationType>("Tek Yön");

  const setDropOffLocationTypeAndDirection = (
    type: TypeDropOffLocationType
  ) => {
    setDropOffLocationType(type);
    setFlightDirection(type);
  };


  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [departureLocationCode, setDepartureLocationCode] = useState("");
  const [arrivalLocationCode, setArrivalLocationCode] = useState("");

  const [departureError, setDepartureError] = useState(false);
  const [arrivalError, setArrivalError] = useState(false);

  const today = new Date();
  const [departureDate, setDepartureDate] = useState<Date | null>(today);
  const [returnDate, setReturnDate] = useState<Date | null>(
    dropOffLocationType !== "Tek Yön" ? today : null
  );

  const getTodayDate = () => {
    const today = new Date();
    return today.toLocaleDateString(locale);
  };

  useEffect(() => {
    if (formFlightData) {
      console.log(formFlightData);
      setFlightDirection(formFlightData.flightDirection || "Tek Yön");
      setFlightClass(formFlightData.flightClass || "Economy");
      setGuestAdultsValue(formFlightData.flightGuests.adultGuests || 1);
      setGuestChildrenValue(formFlightData.flightGuests.childrenGuests || 0);
      setGuestInfantsValue(formFlightData.flightGuests.infantGuests || 0);
      setDepartureLocation(formFlightData.flightLocations.departureLocation || "");
      setDepartureLocationCode(formFlightData.flightLocations.departureLocationCode || "");
      setArrivalLocation(formFlightData.flightLocations.arrivalLocation || "");
      setArrivalLocationCode(formFlightData.flightLocations.arrivalLocationCode || "");

      // Tarih güncellemeleri
      if (formFlightData.flightDates) {
        if (formFlightData.flightDates.departureDate) {
          setDepartureDate(formFlightData.flightDates.departureDate);
        }
        if (formFlightData.flightDates.returnDate) {
          setReturnDate(formFlightData.flightDates.returnDate);
        } else if (formFlightData.flightDirection === "Gidiş ve Dönüş") {
          setReturnDate(departureDate);
        } else {
          setReturnDate(null);
        }
      }
    }
  }, [formFlightData]);
  useEffect(() => {
    if (!formFlightData){
      if (dropOffLocationType !== "Tek Yön") {
        setDepartureDate(today);
        setReturnDate(today);
      } else {
        setDepartureDate(today);
        setReturnDate(null);
      }
    }
  }, [dropOffLocationType]);

  const [flightClass, setFlightClass] = useState("Economy");

  const [guestAdultsValue, setGuestAdultsValue] = useState(1);
  const [guestChildrenValue, setGuestChildrenValue] = useState(0);
  const [guestInfantsValue, setGuestInfantsValue] = useState(0);

  const totalGuests =
    guestAdultsValue + guestChildrenValue + guestInfantsValue;

  const handleChangeGuestData = (data: GuestsObject) => {
    setGuestAdultsValue(data.adults || 0);
    setGuestChildrenValue(data.children || 0);
    setGuestInfantsValue(data.infants || 0);
  };

  const handleDepartureChange = (value: SearchItem) => {
    setDepartureLocation(value.display);
    console.log(value.display);
    setDepartureLocationCode(value.code);
    setDepartureError(false);
    if (value.code){
      setFieldNameShow("locationDropoff");
      setTimeout(function(){
        (document.querySelector('.dropOff input')! as HTMLElement).focus();
      }, 500);
    }
  };

  const handleArrivalChange = (value: SearchItem) => {
    setArrivalLocation(value.display);
    setArrivalLocationCode(value.code);
    setArrivalError(false);
    if (value.code){
      setFieldNameShow("dates");
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    let hasError = false;

    if (!departureLocation) {
      setDepartureError(true);
      hasError = true;
    } else {
      setDepartureError(false);
    }

    if (!arrivalLocation) {
      setArrivalError(true);
      hasError = true;
    } else {
      setArrivalError(false);
    }

    if (hasError) {
      return;
    }

    const formFlightData = {
      flightDirection,
      flightClass,
      flightGuests: {
        adultGuests: guestAdultsValue,
        childrenGuests: guestChildrenValue,
        infantGuests: guestInfantsValue,
        totalGuests: totalGuests,
      },
      flightLocations: {
        departureLocation: departureLocation,
        departureLocationCode: departureLocationCode,
        arrivalLocation: arrivalLocation,
        arrivalLocationCode: arrivalLocationCode,
      },
      flightDates: {
        departureDate: departureDate,
        returnDate:
          flightDirection === "Tek Yön"
            ? undefined
            : returnDate,
      },
    };
    onSubmit && onSubmit();
    navigate("/listing-flights", { state: formFlightData });
  };

  const handleClear = () => {
    setDepartureLocation("");
    setArrivalLocation("");
    setDepartureError(false);
    setArrivalError(false);
    setDropOffLocationType("Tek Yön");
    setFlightDirection("Tek Yön");
    setDepartureDate(today);
    setReturnDate(null);
    setFlightClass("Economy");
    setGuestAdultsValue(1);
    setGuestChildrenValue(0);
    setGuestInfantsValue(0);
  };

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span
              className={`text-neutral-400 ${
                departureError ? "text-red-400" : ""
              }`}
            >
              {departureError
                ? t('heroSearchForm.departureError')
                : t('heroSearchForm.flightFrom')}
            </span>
            <span>{departureLocation || t('heroSearchForm.departure')}</span>

          </button>
        ) : (
          <LocationInput
          type="flight"
          placeHolder={t('heroSearchForm.departure')}
          headingText={t('heroSearchForm.departure')}
          desc={
            departureError
              ? t('heroSearchForm.departureError')
              : t('heroSearchForm.departure')
          }

          className="flex-1"
          value={departureLocation}
          onChange={handleDepartureChange}
          hasError={departureError}

          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span
              className={`text-neutral-400 ${
                arrivalError ? "text-red-400" : ""
              }`}
            >
              {arrivalError
                ? t('heroSearchForm.arrivalError')
                : arrivalLocation
                ? t('heroSearchForm.arrival')
                : t('heroSearchForm.flightTo')}
            </span>
            <span>{arrivalLocation || t('heroSearchForm.arrival')}</span>
          </button>

        ) : (
          <LocationInput
          type="flight"
          placeHolder={t('heroSearchForm.arrival')}
          headingText={t('heroSearchForm.arrival')}
          desc={
            arrivalError
              ? t('heroSearchForm.arrivalError')
              : t('heroSearchForm.arrival')
          }

          className="flex-1 dropOff"
          divHideVerticalLineClass="-inset-x-0.5"
          value={arrivalLocation}
          onChange={handleArrivalChange}
          hasError={arrivalError}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">
              {dropOffLocationType === "Tek Yön"
                ? t('heroSearchForm.flightDirectionOneWay')
                : t('heroSearchForm.flightDirectionRoundTrip')}
            </span>

            <span>
              {departureDate && returnDate
                ? converSelectedDateToString([departureDate, returnDate])
                : converSelectedDateToString([departureDate, null])}
            </span>
          </button>
        ) : (
          <DatesRangeInput
            selectsRange={dropOffLocationType !== "Tek Yön"}
            dateSelectCallback={(dateArray) => {
              // check if dateArray is an array of Date objects and if it is not array it is startDate
              if (!Array.isArray(dateArray)) {
                setDepartureDate(dateArray);
                setFieldNameShow("");
                return;
              }

              var dates = dateArray as [Date | null, Date | null];
              setDepartureDate(dates[0]);
              if (dates[1]) {
                setReturnDate(dates[1]);
                setFieldNameShow("");
              }
            }}
            dropOffLocationType={dropOffLocationType} // Burası, üst bileşenden geçirdiğin değer olmalı
            isHotel={false} // Ya da ilgili değeri geçirin
            isTransfer={false} // Ya da ilgili değeri geçirin

          />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">{t('heroSearchForm.flightDirection')}</span>
            <span>{`${dropOffLocationType == "Tek Yön" ? t('heroSearchForm.flightDirectionOneWay') : t('heroSearchForm.flightDirectionRoundTrip')}`}</span>{" "}
            {/* Uçuş tipi burada gösteriliyor */}
          </button>

        ) : (
          <div className="p-5">
            <span className="block font-semibold text-base sm:text-2xl">
              {t('heroSearchForm.flightDirection')}
            </span>
            <div className="relative mt-5">

              <div className="flex space-x-2 justify-center">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "Tek Yön"
                      ? "bg-black text-white shadow-black/10 shadow-lg"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={() => setDropOffLocationTypeAndDirection("Tek Yön")} // Tek Yön'e tıklanırsa
                >
                  {t('heroSearchForm.flightDirectionOneWay')}
                </div>
                <div

                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "Gidiş ve Dönüş"
                      ? "bg-black shadow-black/10 shadow-lg text-white"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={() =>
                    setDropOffLocationTypeAndDirection("Gidiş ve Dönüş")
                  } // Gidiş ve Dönüş'e tıklanırsa
                >
                  {t('heroSearchForm.flightDirectionRoundTrip')}
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTicketClass = () => {
    const isActive = fieldNameShow === "ticketClass"; // Aktif olup olmadığını kontrol et

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("ticketClass")}
          >
            <span className="text-neutral-400">{t('heroSearchForm.ticketClass')}</span>
            <span>{flightClass}</span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-base sm:text-2xl">
              {t('heroSearchForm.ticketClass')}
            </span>
            <div className="mt-6">
              <div className="flex space-x-2 justify-center">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    flightClass === "Economy"
                      ? "bg-black text-white shadow-black/10 shadow-lg"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={() => setFlightClass("Economy")}
                >
                  {t('heroSearchForm.economy')}
                </div>
                <div

                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    flightClass === "Business"
                      ? "bg-black shadow-black/10 shadow-lg text-white"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={() => setFlightClass("Business")}
                >
                  {t('heroSearchForm.business')}
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";

    // Tüm yolcuları (yetişkinler, çocuklar ve bebekler) topluyoruz
    const totalGuests =
      guestAdultsValue + guestChildrenValue + guestInfantsValue;

    if (totalGuests > 0) {
      guestSelected += `${totalGuests} ${t('heroSearchForm.guest')}`;
    }


    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">{t('heroSearchForm.guest')}</span>
            <span>{guestSelected || t('heroSearchForm.guest')}</span>
          </button>

        ) : (
          <GuestsInput
            defaultValue={{
              adults: guestAdultsValue,
              children: guestChildrenValue,
              infants: guestInfantsValue,
            }}
            onChange={handleChangeGuestData} // Güncellenen fonksiyonu kullan
          />
        )}
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {/*  */}
        {renderInputLocationDropoff()}
        {/*  */}
        {renderInputGuests()}
        {/*  */}
        {renderTicketClass()}
        {/*  */}
        {renderGenerals()}
        {/*  */}
        {renderInputDates()}
      </div>
      <div className="px-4 py-3 flex justify-between rounded-2xl mt-2">
        <ButtonClear onClick={handleClear} className="mr-2" />
        <ButtonSubmit onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default FlightSearchForm;
