/* eslint-disable @typescript-eslint/no-unused-vars */

import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useState, useEffect } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { StayDataType } from "data/types";
import { DEMO_STAY_LISTINGS } from "data/listings";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const formStayData = location.state;
  const [filteredStays, setFilteredStays] = useState<StayDataType[]>(DEMO_STAY_LISTINGS);
  const [searchCriteria, setSearchCriteria] = useState("");

  useEffect(() => {
    if (formStayData && formStayData.stayLocation) {
      const { stayLocation, stayDates, stayGuests } = formStayData;
      
      setSearchCriteria(`${stayLocation}, ${stayGuests.totalGuests} misafir, ${stayDates.startDate} - ${stayDates.endDate}`);

      const filtered = DEMO_STAY_LISTINGS.filter((stay) => {
        const searchTerms = stayLocation.toLowerCase().split(' ');
        const locationMatch = searchTerms.some((term: string) => 
          stay.title.toLowerCase().includes(term) || 
          stay.address.toLowerCase().includes(term)
        );
        
        const guestMatch = stay.maxGuests >= stayGuests.totalGuests;
        const dateMatch = true; // Şimdilik her zaman true

        return locationMatch && guestMatch && dateMatch;
      });

      setFilteredStays(filtered);
    } else {
      setFilteredStays(DEMO_STAY_LISTINGS);
      setSearchCriteria("");
    }
  }, [formStayData]);

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>TODOGO</title>
      </Helmet>

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="hotel"
          currentTab="hotel"
          className="pt-10 pb-24 lg:pb-28 lg:pt-16"
        />
        
        <SectionGridFilterCard 
          className="pb-24 lg:pb-28" 
          data={filteredStays}
          formStayData={formStayData}
        />

        {/* SECTION 1 */}

      </div>
    </div>
  );
};

export default ListingStayPage;
