import React, { FC, useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import FlightDateRangeInput from "./FlightDateRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface FlightSearchFormProps {
  formFlightData?:any;
}

const FlightSearchForm: FC<FlightSearchFormProps> = ({
  formFlightData
}) => {

  const navigate = useNavigate();
  const { t } = useTranslation('home');


  const refGuestContainer = React.createRef<HTMLDivElement>();
  const refClassContainer = React.createRef<HTMLDivElement>();
  const [openGuest, setOpenGuest] = useState(false);
  const [openClass, setOpenClass] = useState(false);
  useOutsideAlerter(refGuestContainer, () => setOpenGuest(false));
  useOutsideAlerter(refClassContainer, () => setOpenClass(false));

  const [flightDirection, setFlightDirection] = useState("Tek Yön");

  const flightClasses = [
    {
      name: "Economy",
    },
    {
      name: "Business",
    },
  ];

  const [flightClass, setFlightClass] = useState("Economy");

  const [guestAdultsValue, setGuestAdultsValue] = useState(1);
  const [guestChildrenValue, setGuestChildrenValue] = useState(0);
  const [guestInfantsValue, setGuestInfantsValue] = useState(0);

  const handleChangeGuestData = (value: number, type: keyof GuestsObject) => {
    if (type === "adults") {
      setGuestAdultsValue(value);
    }
    if (type === "children") {
      setGuestChildrenValue(value);
    }
    if (type === "infants") {
      setGuestInfantsValue(value);
    }
  };

  const totalGuests = guestAdultsValue + guestChildrenValue + guestInfantsValue;

  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [departureLocationCode, setDepartureLocationCode] = useState("");
  const [arrivalLocationCode, setArrivalLocationCode] = useState("");

  const handleDepartureChange = (value: any) => {
    setDepartureLocation(value.Display);
    setDepartureLocationCode(value.Code);
    setDepartureError(false);
  };

  const handleArrivalChange = (value: any) => {
    setArrivalLocation(value.Display);
    setArrivalLocationCode(value.Code);
    setArrivalError(false);
  };
 
  const [departureDate, setDepartureDate] = useState<Date>(new Date());
  const [returnDate, setReturnDate] = useState<Date | null>(flightDirection !== "Tek Yön" ? new Date() : null);

  const [departureError, setDepartureError] = useState(false);
  const [arrivalError, setArrivalError] = useState(false);

  
  useEffect(() => {
    if (formFlightData) {
      setFlightDirection(formFlightData.flightDirection || "Tek Yön");
      setFlightClass(formFlightData.flightClass || "Economy");
      setGuestAdultsValue(formFlightData.flightGuests.adultGuests || 1);
      setGuestChildrenValue(formFlightData.flightGuests.childrenGuests || 0);
      setGuestInfantsValue(formFlightData.flightGuests.infantGuests || 0);
      setDepartureLocation(formFlightData.flightLocations.departureLocation || "");
      setDepartureLocationCode(formFlightData.flightLocations.departureLocationCode || "");
      setArrivalLocation(formFlightData.flightLocations.arrivalLocation || "");
      setArrivalLocationCode(formFlightData.flightLocations.arrivalLocationCode || "");

      // Tarih güncellemeleri
      if (formFlightData.flightDates) {
        if (formFlightData.flightDates.departureDate) {
          setDepartureDate(formFlightData.flightDates.departureDate);
        }
        if (formFlightData.flightDates.returnDate) {
          setReturnDate(formFlightData.flightDates.returnDate);
        } else if (formFlightData.flightDirection === "Gidiş ve Dönüş") {
          setReturnDate(departureDate);
        } else {
          setReturnDate(null);
        }
      }
    }
  }, [formFlightData]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    let hasError = false;

    if (!departureLocation) {
      setDepartureError(true);
      hasError = true;
    } else {
      setDepartureError(false);
    }

    if (!arrivalLocation) {
      setArrivalError(true);
      hasError = true;
    } else {
      setArrivalError(false);
    }

    if (hasError) {
      return;
    }

    const formFlightData = {
      flightDirection,
      flightClass,
      flightGuests: {
        adultGuests: guestAdultsValue,
        childrenGuests: guestChildrenValue,
        infantGuests: guestInfantsValue,
        totalGuests: totalGuests,
      },
      flightLocations: {
        departureLocation: departureLocation,
        departureLocationCode: departureLocationCode,
        arrivalLocation: arrivalLocation,
        arrivalLocationCode: arrivalLocationCode,
      },
      flightDates: {
        departureDate: departureDate,
        returnDate:
          flightDirection === "Tek Yön"
            ? undefined
            : returnDate,
      },
    };
    console.log(formFlightData);

    navigate("/listing-flights", { state: formFlightData });
  };

  const renderGuest = () => {
    return (
      <div className="">
        <div ref={refGuestContainer} className="relative">
          <>
            <div
              className={`cursor-pointer px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-0 text-xs`}
              onClick={() => setOpenGuest(!openGuest)}
            >
              <span>{`${totalGuests || ""} ${t('heroSearchForm.guest')}`}</span>
              <ChevronDownIcon
                className={`${
                  openGuest ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}

                aria-hidden="true"
              />
            </div>
            <Transition
              show={openGuest}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="absolute z-20 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 left-1/2 -translate-x-1/2  py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black/5 dark:ring-white/10">
                <NcInputNumber
                  className="w-full"
                  defaultValue={guestAdultsValue}
                  onChange={(value) =>
                    handleChangeGuestData(value, "adults")
                  }
                  max={7}
                  min={1}
                  label={t('heroSearchForm.adult')}
                  desc={t('heroSearchForm.adultDesc')}
                />

                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestChildrenValue}
                  onChange={(value) =>
                    handleChangeGuestData(value, "children")
                  }
                  max={7}
                  label={t('heroSearchForm.child')}
                  desc={t('heroSearchForm.childDesc')}
                />

                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestInfantsValue}
                  onChange={(value) =>
                    handleChangeGuestData(value, "infants")
                  }
                  max={7}
                  label={t('heroSearchForm.infant')}
                  desc={t('heroSearchForm.infantDesc')}
                />
              </div>

            </Transition>
          </>
        </div>
      </div>
    );
  };

  const renderSelectClass = () => {
    return (
      <div className="">
        <div ref={refClassContainer} className="relative">
          <>
            <div
              className={`px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-0 text-xs`}
              onClick={() => setOpenClass(!openClass)}
            >
              <span>{flightClass}</span>
              <ChevronDownIcon
                className={`${
                  openClass ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </div>
            <Transition
              show={openClass}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="absolute z-30 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                  <div className="relative grid bg-white dark:bg-neutral-800 p-3">
                    {flightClasses.map((item) => (
                      <button
                        key={item.name}
                        onClick={(e) => {
                          e.preventDefault();
                          setFlightClass(item.name);
                          setOpenClass(false);
                        }}
                        className="flex items-center p-2 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <p className="text-sm font-medium">{item.name}</p>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </Transition>
          </>
        </div>
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className="pb-3 flex justify-center space-x-3">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
            flightDirection === "Tek Yön"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setFlightDirection("Tek Yön")}
        >
          {t('heroSearchForm.flightDirectionOneWay')}
        </div>
        <div

          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
            flightDirection === "Gidiş ve Dönüş"
              ? "bg-black shadow-black/10 shadow-lg text-white"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setFlightDirection("Gidiş ve Dönüş")}
        >
          {t('heroSearchForm.flightDirectionRoundTrip')}
        </div>

        <div className=" border-r border-slate-200 dark:border-slate-700 "></div>

        <div className="border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        <div className="border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderGuest()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form className="w-full relative " onSubmit={handleSubmit}>
        {renderRadioBtn()}
        <div className="flex w-full rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
          <LocationInput
            type="flight"
            placeHolder={t('heroSearchForm.flightFrom')}
            desc={
              departureError
                ? t('heroSearchForm.departureError')
                : t('heroSearchForm.departure')
            }

            className="flex-1"
            value={departureLocation}
            onChange={handleDepartureChange}
            hasError={departureError}
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            type="flight"
            placeHolder={t('heroSearchForm.flightTo')}
            desc={
              arrivalError
                ? t('heroSearchForm.arrivalError')
                : t('heroSearchForm.arrival')
            }

            className="flex-1"
            divHideVerticalLineClass=" -inset-x-0.5"
            value={arrivalLocation}
            onChange={handleArrivalChange}
            hasError={arrivalError}
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <FlightDateRangeInput
            selectsRange={flightDirection !== "Tek Yön"}
            className="flex-1"
            startDate={departureDate}
            endDate={returnDate}
            onChange={({ startDate, endDate }) => {
              setDepartureDate(startDate);
              console.log(startDate);
              if (flightDirection !== "Tek Yön") {
                setReturnDate(endDate);
              } else {
                setReturnDate(null);
              }
            }}
            onSubmit={handleSubmit}
          />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default FlightSearchForm;
