import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/home.json';
import translationENListing from './locales/en/listing.json';
import translationTR from './locales/tr/home.json';
import translationTRListing from './locales/tr/listing.json';

const resources = {
  en: {
    home: translationEN,
    listing: translationENListing
  },
  tr: {
    home: translationTR,
    listing: translationTRListing
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'tr',
    supportedLngs: ['tr', 'en'],
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'home',
  });

export default i18n; 