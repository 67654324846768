import axios from '../utils/axios';
import { LoginRequestDto, RegisterRequestDto, User, UpdateUserRequestDto, GetCurrentUserResponse } from '../types/auth.types';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
    exp: number;
    // ... diğer token içeriği
}

export const getCurrentUserFromApi = async (): Promise<GetCurrentUserResponse> => {
    const response = await axios.get(`/api/user`);
    return response.data;
};

export const register = async (data: RegisterRequestDto): Promise<User> => {
    const response = await axios.post(`/api/user/register`, data);
    if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
    }
    return response.data.user;
};

export const login = async (data: LoginRequestDto): Promise<User> => {
    const response = await axios.post(`/api/user/login`, data);
    if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
    }
    return response.data.user;
};

export const updateUser = async (data: UpdateUserRequestDto): Promise<User> => {
    const response = await axios.put(`/api/user`, data);
    return response.data.user;
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};

export const isTokenExpired = (): boolean => {
    const token = localStorage.getItem('token');
    if (!token) return true;
    
    try {
        const decoded = jwtDecode<DecodedToken>(token);
        const currentTime = Date.now() / 1000;
        
        // Token süresi dolmadan 5 dakika önce logout yapalım
        return decoded.exp < (currentTime + 300);
    } catch {
        return true;
    }
};

export const getCurrentUser = (): User | null => {
    if (isTokenExpired()) {
        logout();
        return null;
    }

    const userStr = localStorage.getItem('user');
    if (userStr) {
        return JSON.parse(userStr);
    }
    return null;
};

export const isAuthenticated = (): boolean => {
    return !isTokenExpired() && !!localStorage.getItem('token');
}; 