import React, { FC, useState, useEffect, useCallback } from "react";
import { SelectableFaresRuleModel } from "types/flight.types";

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    price: number;
    selectedPackage: string | null;
    flightDirection: string | "Tek Yön" | "Gidiş ve Dönüş";
    flightDirectionId: number;
    flightClass: string | "Economy" | "Business";
    flightGuests: {
      adultGuests: number;
      childrenGuests: number;
      infantGuests: number;
      totalGuests: number;
    };
    flightLocations: {
      departureLocation: string;
      departureLocationCode: string;
      arrivalLocation: string;
      arrivalLocationCode: string;
    };
    flightDates: {
      departureDate: Date;
      returnDate?: Date;
    };
    flightSeatNumber: string;
    flightInfo: {
      segment: Array<{
          airlineLogo: string;
          airlineName: string;
          departureCode: string;
          departureTime: string;
          arrivalCode: string;
          arrivalTime: string;
          flightCode: string;
          flightDuration: string;
          flightTicketNumber: string;
          flightTicketPNR: string;
          marketingAirlineName: string;
        }>
    },
    flightPackage: Array<{
        code:string;
        codeName:string;
        baggageWeight: string;
        cabinBaggageWeight: string;
        price:number;
        fareRules:SelectableFaresRuleModel[];
    }>
    flightType: string;
  };
  onSelect: (data: FlightCardProps['data'] & { selectedPackage: string | null }) => void;
  isSelected?: boolean;
}

const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
  onSelect,
  isSelected = false,
}) => {

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [selectedPackage, setSelectedPackage] = useState<string>("");
  const [currentPrice, setCurrentPrice] = useState(data.price);
  const [logoError, setLogoError] = useState<{[key: string]: boolean}>({});

  const updatePrices = useCallback(() => {


    if (data.flightPackage) {
      const ecoFlyPrice = Number(data.flightPackage[0].price);

      setCurrentPrice(ecoFlyPrice);
    } else {

      setCurrentPrice(data.price);
    }
  }, [data]);

  useEffect(() => {
    updatePrices();
  }, [updatePrices]);

  //useEffect(() => {
  //  if (data.flightPackage) {
  //    setSelectedPackage(data.flightPackage[0].Code);
  //  }
  //}, [data]);

  const handleCardClick = (e: React.MouseEvent) => {
    if (isSelected) return; // Seçiliyse tıklanamaz
    if ((e.target as HTMLElement).closest('.package-selection-area')) {
      return;
    }
    if (!data.flightPackage){
      handleSelectFlight();
    }

    console.log(isOpen);
    setIsOpen(!isOpen);
    if (isOpen) {
      if (data.flightPackage && selectedPackage){
        handleSelectFlight();
      }
    } else {
      setSelectedPackage("");
    }
  };

  const handleSelectFlight = useCallback(() => {
    if (!data.flightPackage) {
      onSelect({ ...data, selectedPackage: null });
    } else if (isOpen && selectedPackage) {
      onSelect({ ...data, price: currentPrice, selectedPackage: selectedPackage });
    } else {
      setIsOpen(true);
    }
  }, [data, selectedPackage, onSelect, setIsOpen, currentPrice]);

  const renderPackageDetails = (packageData: any) => {
    const packagePrice = packageData.price;
    
    const getRuleIcon = (rule:SelectableFaresRuleModel) => {
      switch(rule.serviceGroup){
        case "BG": return "suitcase";
        case "CY": return "briefcase";
        case "ML": return "utensils";
        default: return "info-circle";
      }
    }

    return (
      <div className="flex flex-col h-full">
        <div className="flex-grow">
          <h3 className="font-medium text-lg">{(packageData.codeName)}</h3>
          <ul className="text-sm">
           {/* <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-suitcase me-2"></i> 
              {packageData.baggageWeight}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-briefcase me-2"></i> 
              {packageData.cabinBaggageWeight}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-utensils me-2"></i> 
              {packageData.Catering}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-exchange-alt me-2"></i> 
              {packageData.Exchange}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-times me-2"></i> 
              {packageData.Cancel}
            </li> */}
            {packageData.fareRules.map((rule:SelectableFaresRuleModel, index:number) => (
              <li key={index} className="text-sm text-neutral-500 font-normal mt-4">
                <i className={`las la-${getRuleIcon(rule)} me-2`}></i> 
                {rule.description}
              </li>
            ))}
            <li className="mt-4">
              <span className="text-xl font-semibold text-secondary-6000">
                {formatPrice(packagePrice)[0]}
                <span className="text-sm">,{formatPrice(packagePrice)[1]}</span> TL
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderPackages = () => {
    if (!data.flightPackage) return null;

    return (
      <div className="package-selection-area mt-6 border-t border-neutral-100 dark:border-neutral-800 pt-6">
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
          {data.flightPackage.map((p) => (
            <div
              key={p.code}
              className={`p-4 rounded-3xl border cursor-pointer ${
                selectedPackage === p.code
                  ? "border-neutral-100 bg-neutral-50 dark:bg-black/20 dark:border-neutral-800"
                  : "border-neutral-100 dark:bg-neutral-900 dark:border-neutral-800"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handlePackageSelect(p.code);
              }}
            >
              {renderPackageDetails(p)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const formatPrice = (price: number): [string, string] => {
    const [integerPart, decimalPart] = price.toFixed(2).split('.');
    return [
      integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      decimalPart
    ];
  };

  const handlePackageSelect = (packageName: string) => {
    setSelectedPackage(packageName);
    const selectedPackageItem = data.flightPackage.find((x) => x.code === packageName);
    if (selectedPackageItem) {
      const newPrice = selectedPackageItem.price;

      setCurrentPrice(newPrice);
    }
  };

  useEffect(() => {
    if (selectedPackage) {
      handleSelectFlight(); // This will be called when selectedPackage changes
    }
  }, [selectedPackage, handleSelectFlight]);
  
  const isMultiSegment = data.flightInfo.segment.length > 1;

  const renderAirlineLogo = (airlineName: string) => {
    if (logoError[airlineName]) {
      // Logo yüklenemediğinde gösterilecek fallback içerik
      return (
        <div className="flex items-center justify-center h-[60px]">
          <span className="text-neutral-500">{airlineName}</span>
        </div>
      );
    }

    try {
      return (
        <img 
          src={`/images/airlines/${airlineName}.png`}
          style={{width: "100%", height: "60px", objectFit: "contain"}}
          alt={airlineName}
          onError={() => setLogoError(prev => ({ ...prev, [airlineName]: true }))}
        />
      );
    } catch (error) {
      // require() hatası durumunda
      if (!logoError[airlineName]) {
        setLogoError(prev => ({ ...prev, [airlineName]: true }));
      }
      return (
        <div className="flex items-center justify-center h-[60px]">
          <span className="text-neutral-500">{airlineName}</span>
        </div>
      );
    }
  }

  return (
    <div
      className={`nc-FlightCard group p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
      dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className} ${
        isSelected ? "cursor-default" : "cursor-pointer"
      }`}
      data-nc-id="FlightCard"
      onClick={handleCardClick}
    >
      <div className={`xl:pr-20 relative ${className}`} data-nc-id="FlightCard">
        {data.flightInfo.segment.map((segment, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div className="relative my-8">
                <div className="border-t border-neutral-100 dark:border-neutral-800 w-[calc(95%-80px)]"></div>
                {index === 1 && isMultiSegment && (
                  <div className="absolute right-0 top-1/2 -translate-y-1/2 bg-white dark:bg-neutral-900">
                    <div className="text-xl font-semibold text-secondary-6000">
                      {formatPrice(currentPrice)[0]}
                      <span className="text-sm">,{formatPrice(currentPrice)[1]}</span> TL
                    </div>
                    <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5 text-right">
                      {data.flightSeatNumber}
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* MOBİL GÖRÜNÜM */}
            <div className="flex xl:hidden flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0">
              <div className="block w-full space-y-0">
                {/* Üst kısım - 3 sütun + fiyat (768px ve üstü) */}
                <div className="flex justify-center">
                  <div className="flex items-center justify-center w-6">
                    {renderAirlineLogo(segment.airlineName)}
                    <div className="font-medium text-sm ml-2">{segment.marketingAirlineName}</div>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-4">
                  <div className="flex-1 items-center justify-between">
                    {/* Havayolu bilgisi */}
                    <div className="font-medium">
                      {segment.departureCode} - {segment.arrivalCode}
                    </div>
                    <div className="text-sm text-neutral-500">{data.flightType}</div>
                    <div className="block xl:hidden">
                      <div className="text-sm text-neutral-500 mt-2">{segment.flightCode}</div>
                    </div>
                  </div>

                  {/* Uçuş süresi */}
                  <div className="flex-1 text-center">
                    <div className="font-medium text-sm">
                      {segment.departureTime} - {segment.arrivalTime}
                    </div>
                    <div className="text-sm text-neutral-500">{segment.flightDuration}</div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="flex-1 items-center justify-between">
                    {/* Fiyat - 767px ve altında görünür */}
                    {!isMultiSegment && (
                      <div className="md:hidden text-center">
                        <div className="text-md font-semibold text-secondary-6000">
                          {formatPrice(currentPrice)[0]}
                          <span className="text-sm">,{formatPrice(currentPrice)[1]}</span> TL
                        </div>
                        <div className="text-sm text-neutral-500">
                          {data.flightSeatNumber}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Alt kısım - Sadece Buton */}
                {/* {!isMultiSegment && (
                  <div className="flex justify-center">
                    <span
                      className={`w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center ${
                        isSelected ? "cursor-default" : "cursor-pointer"
                      } ${isSelected ? "" : "hover:bg-neutral-100 dark:hover:bg-neutral-700"} border border-neutral-100 dark:border-neutral-700`}
                      onClick={(e) => {
                        if (isSelected) return;
                        e.stopPropagation();
                        handleSelectFlight();
                      }}
                    >
                      <i className="text-xl las la-angle-right"></i>
                    </span>
                  </div>
                )} */}
              </div>
            </div>

            {/* DESKTOP GÖRÜNÜM */}
            <div className="hidden xl:flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
              {/* LOGO IMG - sadece desktop'ta görünecek */}
              <div className="block w-24 xl:w-32 flex-shrink-0">
                {renderAirlineLogo(segment.airlineName)}
              </div>

              <div className="flex flex-grow items-center space-x-4">
                <div className="flex-[4] whitespace-nowrap">
                  <div className="font-medium text-lg">{segment.marketingAirlineName}</div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {data.flightType}
                  </div>
                </div>

                <div className="flex-[4] whitespace-nowrap">
                  <div className="font-medium text-lg">
                    {segment.departureCode} - {segment.arrivalCode}
                  </div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {segment.flightCode}
                  </div>
                </div>

                <div className="flex-[4] whitespace-nowrap">
                  <div className="font-medium text-lg">
                    {segment.departureTime} - {segment.arrivalTime}
                  </div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {segment.flightDuration}
                  </div>
                </div>

                {/* Desktop fiyat */}
                <div className="flex-[4] whitespace-normal sm:whitespace-nowrap text-right">
                  {!isMultiSegment && (
                    <>
                      <div className="text-xl font-semibold text-secondary-6000 break-normal">
                        {formatPrice(currentPrice)[0]}
                        <span className="text-sm">,{formatPrice(currentPrice)[1]}</span> TL
                      </div>
                      <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                        {data.flightSeatNumber}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}

        {/* Ana seçim butonu - sadece desktop'ta görünecek */}
        {/* <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 hidden xl:flex w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full items-center justify-center ${
            isSelected ? "cursor-default" : "cursor-pointer"
          } ${isSelected ? "" : "hover:bg-neutral-100 dark:hover:bg-neutral-700"} border border-neutral-100 dark:border-neutral-700`}
          onClick={(e) => {
            if (isSelected) return;
            e.stopPropagation();
            handleSelectFlight();
          }}
        >
          <i className="text-xl las la-angle-right"></i>
        </span> */}
      </div>
      
      {/* DETAIL */}
      {isOpen && data.flightPackage && renderPackages()}

    </div>
  );
};

export default FlightCard;
