import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface AccordionProps {
  title: string;
  isActive: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  children: React.ReactNode;
  stepNumber: number;
  onToggle: () => void;
}

const CheckoutAccordion: React.FC<AccordionProps> = ({
  title,
  isActive,
  isCompleted,
  isDisabled,
  children,
  stepNumber,
  onToggle,
}) => {
  return (
    <div className={`border rounded-lg mb-4 ${isDisabled ? 'opacity-50' : ''}`}>
      <button
        className={`w-full p-4 flex items-center justify-between text-left rounded-lg
          ${isActive ? 'bg-primary-50 dark:bg-neutral-900' : 'bg-white dark:bg-neutral-900'}
          ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        onClick={onToggle}
        disabled={isDisabled}
      >
        <div className="flex items-center space-x-4">
          <div className={`
            w-8 h-8 rounded-full flex items-center justify-center text-white
            ${isCompleted ? 'bg-green-500 dark:bg-green-500' : isActive ? 'bg-primary-600 dark:bg-primary-600' : 'bg-neutral-500 dark:bg-neutral-500'}
          `}>
            {isCompleted ? (
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            ) : (
              <span>{stepNumber}</span>
            )}
          </div>
          <span className="text-lg font-medium">{title}</span>
        </div>
        <ChevronDownIcon
          className={`w-6 h-6 transition-transform ${isActive ? 'transform rotate-180' : ''}`}
        />
      </button>
      {isActive && (
        <div className="p-4 border-t">
          {children}
        </div>
      )}
    </div>
  );
};

export default CheckoutAccordion; 