import React, { useState } from "react";
import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import { useTranslation } from "react-i18next";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {

  const { t } = useTranslation('home');
  const widgetMenus: WidgetFooterMenu[] = [
    {
      id: "5",
      title: t('footer.categories'),
      menus: [
        { href: "/", label: t('footer.home') },
        { href: "/", label: t('footer.blog') },
      ],


    },
    {
      id: "1",
      title: t('footer.corporate'),
      menus: [
        { href: "/about", label: t('footer.about') },
        { href: "/privacy-cookie", label: t('footer.privacyCookie') },
        { href: "/cancel-refund", label: t('footer.cancelRefund') },
      ],


    },
    {
      id: "2",
      title: t('footer.support'),
      menus: [
        { href: "/#faq", label: t('footer.faq') },
        { href: "/contact", label: t('footer.contact') },
      ],


    },
    {
      id: "4",
      title: t('footer.dijitalpark'),
      menus: [
        {
          href: "#",
          label:
            t('footer.address'),
        },

        {
          href: "#",
          label:
            t('footer.ecsatTravel'),
        },

        {
          href: "#",
          label:
            t('footer.legalInformation'),

        },
      ],
    },
  ];

  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

  const toggleMenu = (id: string) => {
    setOpenMenus((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleAddressClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open("https://www.google.com/maps?ll=41.014224,29.206262&z=17&t=m&hl=tr&gl=TR&mapclient=embed&cid=18284666542394016779", "_blank", "noopener,noreferrer");
  };

  const renderWidgetMenuItem = (menu: WidgetFooterMenu) => {
    const isOpen = openMenus[menu.id] || false;
    return (
      <div key={menu.id} className="text-sm w-full">
        <button
          className="font-semibold text-neutral-700 dark:text-neutral-200 text-center w-full md:hidden"
          onClick={() => toggleMenu(menu.id)}
        >
          {menu.title}
        </button>
        <ul className={`mt-2 space-y-2 text-center ${isOpen ? "block" : "hidden"} md:hidden`}>
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                className="text-neutral-500 dark:text-neutral-400"
                href={item.href}
                onClick={menu.id === "4" ? handleAddressClick : undefined}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200 text-left hidden md:block">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4 hidden md:block">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                className="text-neutral-500 dark:text-neutral-400"
                href={item.href}
                onClick={menu.id === "4" ? handleAddressClick : undefined}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container">
        <div className="flex justify-center items-center">
          <Logo />
        </div>
        {/* Butonlar sadece 767px ve altı ekranlarda görünür */}
        <div className="flex flex-row justify-center gap-5 mt-20 md:hidden">
          {widgetMenus
            .filter((menu) => menu.id !== "4")
            .map((menu) => renderWidgetMenuItem(menu))}
        </div>
        <div className="mt-10 md:hidden text-center">
          {widgetMenus
            .filter((menu) => menu.id === "4")
            .map((menu) => renderWidgetMenuItem(menu))}
        </div>
        {/* Grid düzeni sadece daha büyük ekranlarda görünür */}
        <div className="hidden md:grid grid-cols-2 md:grid-cols-4 gap-y-10 gap-x-5 sm:gap-x-8 lg:gap-x-10 border-solid border-gray-300 mt-20 justify-items-center">
          {widgetMenus.map(renderWidgetMenuItem)}
        </div>
      </div>
      <div className="flex flex-col items-center mt-20">
        <div className="flex items-center mb-10">
          <SocialsList1 className="flex items-center space-x-3" />
        </div>
        <span className="reserved text-neutral-500 dark:text-[neutral-400] text-center">
          {t('footer.reserved')}
        </span>
      </div>

    </div>
  );
};

export default Footer;
