/* eslint-disable @typescript-eslint/no-unused-vars */

import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useState, useEffect } from "react";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { StayDataType } from "data/types";
import { DEMO_STAY_LISTINGS } from "data/listings";


import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";


export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({className = "",}) => {

  const location = useLocation();
  const formStayData = location.state;
  const [filteredStays, setFilteredStays] = useState<StayDataType[]>(DEMO_STAY_LISTINGS);
  const [searchCriteria, setSearchCriteria] = useState("");

  useEffect(() => {
    if (formStayData && formStayData.stayLocation) {
      const { stayLocation, stayDates, stayGuests } = formStayData;
      
      setSearchCriteria(`${stayLocation}, ${stayGuests.totalGuests} misafir, ${stayDates.startDate} - ${stayDates.endDate}`);

      const filtered = DEMO_STAY_LISTINGS.filter((stay) => {
        const searchTerms = stayLocation.toLowerCase().split(' ');
        const locationMatch = searchTerms.some((term: string) => 
          stay.title.toLowerCase().includes(term) || 
          stay.address.toLowerCase().includes(term)
        );
        
        const guestMatch = stay.maxGuests >= stayGuests.totalGuests;
        const dateMatch = true; // Şimdilik her zaman true

        return locationMatch && guestMatch && dateMatch;
      });

      setFilteredStays(filtered);
    } else {
      setFilteredStays(DEMO_STAY_LISTINGS);
      setSearchCriteria("");
    }
  }, [formStayData]);

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>TODOGO</title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
        <SectionHeroArchivePage currentPage="hotel" currentTab="hotel" />
      </div>

      {/* SECTION */}
      <div className="container pb-24 lg:pb-28">
        <SectionGridHasMap 
          data={filteredStays}
          formStayData={formStayData}
        />
      </div>

      <div className="container overflow-hidden">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>

        {/* SECTION */}

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default ListingStayMapPage;
